import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { Draggable } from 'react-beautiful-dnd';
import * as mobx from 'mobx';
import uuid from 'uuid';

import arrowUpIcon from '../../../Images/arrow-up.png';
import trashIcon from '../../../Images/trash.svg';
import uploadIcon from '../../../Images/upload-icon.png';
import addIcon from '../../../Images/add-icon.png';

import FileBlock from '../FileBlock';
import FileBinFolder from '../FileBinFolder';
import OtherDealsFolder from '../OtherDealsFolder';
import DownloadBtn from '../../buttons/DownloadBtn';
import UploadBtn from '../../buttons/UploadBtn';

import FoldersBreadcrumbs from './FoldersBreadcrumbs';

import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import UpdateModel from '../../../Models/UpdateModel';
import DocPreviewModel from '../../../Models/DocPreviewModel';
import FileBinModel from '../../../Models/FileBinModel';
import UploadModel from '../../../Models/loadModels/UploadModel';
import PopUpModel from '../../../Models/PopUpModel';
import OtherDealsModel from '../../../Models/OtherDealsModel';

import EditFolderNameForm from '../../forms/EditFolderNameForm';
import EditBtn from '../../buttons/EditBtn';
import Helper from '../../../Helpers/helper';

@observer class FilesSection extends Component {
    constructor(props) {
        super(props);

        this.disposer = null;
        this.uploadInputRef = React.createRef();

        this.state = {
          isInTrash: false,
          fileBinFiles: [],
          activeFolderId: 'FileBinFiles'
        }
    }

    onDragOver = (e) => {
        e.preventDefault()
    }

    onDrop = (e) => {
        e.preventDefault();

        const files = e.dataTransfer.files;
        UploadModel.handleFilesUpload(files, 'FileBinFiles', FileBinModel.activeFolderId);//dest must be not right
    }

    openFileDialog = (e) => {
        this.uploadInputRef.current.click();
    }

    componentDidMount() {
      this.disposer = mobx.autorun(() => {
          const activeFolderId = FileBinModel.activeFolderId,
                { formatedDealsForPreview } = OtherDealsModel,
                {trashId} = TrashModel;

          const fileBinFiles = [...RootModel.FileBinFiles].filter(file => {
                  if(file.parent_id){
                    return !file.isOtherDeal && file.parent_id.toString() === activeFolderId.toString()
                  }
                }),
                activeDocData = DocPreviewModel.activeDocData,
                isInTrash = TrashModel.determineIsInTrash();

          this.setState({activeFolderId, formatedDealsForPreview, fileBinFiles, activeDocData, isInTrash, trashId});
        });
    }

    componentWillUnmount(){/// TODO: ADD DELETE BUTTON
      this.disposer();
    }

    render() {
      const {activeFolderId, activeDocData, fileBinFiles,
             isInTrash, trashId, inOtherDeals} = this.state,
            { provided } = this.props;
            
      return (<Fragment>
              {fileBinFiles.length > 0 &&
                  fileBinFiles.map((file, index) => {
                  let isPreviewed = activeDocData ? (file.id.toString() === activeDocData.id.toString()) : null;

                  return <Draggable draggableId={file.id} index={index} key={file.id}>
                            {provided => <FileBlock provided={provided} key={file.id} data={file}
                                                    isPreviewed={isPreviewed} isInTrash={isInTrash} inOtherDeals={inOtherDeals} />}
                         </Draggable>})}

                {fileBinFiles.length === 0 && !inOtherDeals &&
                    <div className="upload-zone">
                        {!isInTrash &&
                        <div className="container" onClick={this.openFileDialog}>
                            <span className="icon">
                                <img src={uploadIcon} alt="upload"/>
                            </span>
                            <span>Drop files here <br/> or click <span className="underline">here</span> to upload</span>
                            <input multiple type="file"
                                  onChange={e => UploadModel.handleFilesUpload(e.target.files, 'FileBinFiles', activeFolderId)}
                                  ref={this.uploadInputRef}/>
                        </div>}
                        {isInTrash && (activeFolderId.toString() === trashId.toString()) && <div>
                            Trash is empty.
                        </div>}
                    </div>
                }
            </Fragment>
      )
    }
}

export default FilesSection;
