import { observable, action } from 'mobx';
import JSZip from 'jszip';

import Helper from '../../Helpers/helper';
import RootModel from '../RootModel';
import PopUpModel from '../PopUpModel';
import FileBinModel from '../FileBinModel';
import DatePickerModel from '../DatePickerModel';
import UploadModel from './UploadModel';
import { THREAD_ID } from '../../constants/api';

class UploadZipModel {
  @observable unzipConfirmed = null;
  @observable unzipData = null;

  @action setUnzipData(data){
    this.unzipData = data || null;
    if(!data) this.unzipConfirmed = null;
  }

  @action unzipConfirm(e, confirm){
    const {files, dest, subSectionId, parentDest} = UploadModel.uploadData;

    this.unzipConfirmed = confirm;
    PopUpModel.closeAll();

    if( (this.unzipData && this.unzipData.isFolderInZip &&        // if there are folders in zip
        (this.unzipData.dest !== 'FileBinFiles' && confirm ||     // and yes' chosen in a deal tab, (load full zip file?)
         this.unzipData.dest === 'FileBinFiles' && !confirm) ) || // or 'no' chosen in file bin, (unzip file?)
        (!this.unzipData.isFolderInZip && !confirm && this.unzipData.dest !== 'FileBinFiles') ){ // or no folders in zip && in deal tab, 'no' chosen (unzip fie?)
      return UploadModel.uploadOrdinaryFiles(files, dest, subSectionId, parentDest);
    }else if(this.unzipData && this.unzipData.isFolderInZip && this.unzipData.dest !== 'FileBinFiles' && !confirm){//refusing to upload a zip with folders to deal section
      return this.setUnzipData();
    }else{
      return this.uploadZipFile(files, dest, subSectionId, parentDest);
    }
  }

  determineFolderInZip(zipObj){
    for (const fullFileName in zipObj.files) {
      if(fullFileName.indexOf('/') > -1) return true;
    }
    return false;
  }
  //upload zip
  @action async uploadZipFile(files, dest, subSectionId, parentDest){// 1
    UploadModel.setUploadData({files, dest, subSectionId, parentDest});
    const zip = new JSZip();

    if(this.unzipConfirmed !== null){
      this.setUnzipData();
      PopUpModel.closeAll();
    }
    zip.loadAsync(files[0])
    .then(async zipObj => {
      let folders = [], uploadFiles = [];

      for (const fullFileName in zipObj.files) {

        // skip resource fork on macOS
        if (fullFileName.includes("__MACOSX")) continue;

        let folderId, unzipedFile = zipObj.files[fullFileName];

        if(!THREAD_ID && fullFileName.indexOf('/') > -1){//if a file is in folder
          let newFolders = await this.findParentFolderId(unzipedFile, fullFileName, folders);
          folders = [...folders, ...newFolders.folders];
          folderId = newFolders.parentId;
        }

        if(fullFileName[fullFileName.length - 1] !== '/'){
          unzipedFile.type = (unzipedFile.name.indexOf('.') > -1) ? unzipedFile.name.split('.')[1].toUpperCase() : '';
          unzipedFile = await zip.files[fullFileName].async('blob').then(function (fileData) {
            return new File([fileData], fullFileName);
          })

          if(folderId){
            unzipedFile.folderId = folderId;
          }

          if (THREAD_ID && unzipedFile.name.indexOf('/') > -1) {
            // in thread mode delete folder name in files
            const splittedName = unzipedFile.name.split("/");

            Object.defineProperty(unzipedFile, 'name', {
              writable: true,
              value: splittedName[splittedName.length - 1]
            });
          }

          uploadFiles.push(unzipedFile);
        }
      }

      UploadModel.setUploadData({
        files: uploadFiles,
        dest,
        subSectionId,
        parentDest,
        inZip: true
      });

      if (parentDest === 'Banks') {
        this.uploadBankSubDocs();
      } else if (dest === 'Banks' && !Helper.isBankMonthToDate(dest, subSectionId)) {
        let subSection = RootModel[dest].find(subSection => subSection.id === subSectionId);
        if (subSection.isDeletable && !PopUpModel.activePopUp) {
          DatePickerModel.toggleDatePicker(subSectionId);
        } else{
          UploadModel.uploadBankDocuments();
        }
      } else {
        UploadModel.uploadFiles(true);
      }
    })
    .catch(err => console.error(err));
  }

  async findParentFolderId(unzipedFile, fullFileName, folders){
    let newFolders = [], parentId, currFolders = fullFileName.split('/');
    currFolders = (unzipedFile[unzipedFile.length - 1] === '/') ? currFolders : currFolders.splice(0, currFolders.length - 1);
    folders = [...folders];

    for(let i = 0; i < currFolders.length; i++){
      parentId = FileBinModel.activeFolderId;

      if(currFolders.length > 1 && i > 0){//find parent id for this folder
        parentId = folders.find(folder => folder.name === currFolders[i - 1]);//if undefined, it will be set to activeFolderId in submitAddFolderForm method
        if(parentId) parentId = parentId.id;
      }
      let isCurrentFolderInFolders = folders.find(folder => folder.name === currFolders[i]);

      if(isCurrentFolderInFolders){
        parentId = isCurrentFolderInFolders.id;
      }else if(!folders.length || !isCurrentFolderInFolders){
        parentId = await FileBinModel.submitAddFolderForm(currFolders[i], parentId);
        newFolders.push({name: currFolders[i], id: parentId});
        folders.push({name: currFolders[i], id: parentId});
      }else if(folders.length && currFolders.length === 1){
        parentId = folders.find(folder => folder.name === currFolders[i]).id;
      }
    }

    return {
      parentId,
      folders: newFolders
    };
  }
}//END

const model = new UploadZipModel();
export default model;
