import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import PopUpModel from '../../Models/PopUpModel';
import * as mobx from 'mobx';

import CollapseBtn from '../buttons/CollapseBtn';
import DownloadBtn from '../buttons/DownloadBtn';

import ToolbarSubsection from '../ToolbarSubsection';
import RootModel from '../../Models/RootModel';
import NavigationModel from '../../Models/NavigationModel';
import UploadModel from '../../Models/loadModels/UploadModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';

import Helper from '../../Helpers/helper';
import collapseIcon from '../../Images/arrow-collapse.png';
import contractsIcon from '../../Images/contracts-icon.png';

@observer class Contracts extends Component {
  constructor(props){
    super(props);

    this.disposer = null;
    this.state = {
      contracts: null
    }
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const collapsed = this.props.viewAll ? NavigationModel.viewAllCollapsedSections.Contracts : NavigationModel.collapsedSections.Contracts;
      const contracts = RootModel.Contracts;
      let isEmpty;
      if(contracts.length){
        isEmpty = Helper.isEmptySection(contracts);
      }

      this.setState({contracts, isEmpty, collapsed});
    });
  }

  componentWillUnmount(){
    this.disposer();
  }

  render() {
    const {contracts, isEmpty, collapsed} = this.state;

    if(!contracts) return null;

      return (
          <div className={`toolbar__container contracts ${collapsed ? 'container-collapsed' : ''}`}>
              <div className="head-section">
                  <p className="title">
                      <CollapseBtn collapsed={collapsed} onClick={(e, viewAll) => NavigationModel.handleSectionCollapse('Contracts', this.props.viewAll)} />
                      <img src={contractsIcon} className="icon" alt="contracts" />
                      Contracts
                      <DownloadBtn disabled={isEmpty} onClick={() => DownloadModel.downloadSubsectionFilesInZip('Contracts')} />
                  </p>
              </div>
              {!collapsed && contracts.map(subsection => <React.Fragment key={subsection.id}>
                  <Droppable droppableId={subsection.id}>
                      {provided => <ToolbarSubsection provided={provided} dest="Contracts" key={subsection.id} data={subsection}/>}
                  </Droppable>
              </React.Fragment>)}
          </div>
      )
  }
}

export default Contracts;
