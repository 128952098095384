import React, { Component } from 'react';
import { observer } from 'mobx-react';
import UploadModel from '../../../Models/loadModels/UploadModel';
import FileBinModel from '../../../Models/FileBinModel';
import FilesSectionSingle from './FilesSectionSingle';
import uploadIcon from "../../../Images/upload-icon.png";

@observer class FileBinSection extends Component {

    onDragOver = (e) => {
      e.preventDefault()
    }

    onDrop = (e) => {
      e.preventDefault();
      const files = e.dataTransfer.files;
      UploadModel.handleFilesUpload(files, 'FileBinFiles', FileBinModel.activeFolderId);
    }

    render() {
      const { provided } = this.props;

      return (
        <div onDragOver={this.onDragOver}
            onDrop={this.onDrop}
            ref={provided.innerRef}
            {...provided.droppableProps}
            className="file-manager__container single_section_embed">
              <div className={`folder-section files-section wrapper folder-section-single-embed`}>
                  <FilesSectionSingle />
              </div>
        </div>
      )
    }
}

export default FileBinSection;
