import React from 'react';
import prevBtn from '../../Images/prev-btn.png';

export default function PrevBtn(props) {
    return (
        <button onClick={props.onClick} disabled={props.disabled} className={`btn prev-btn ${props.className ? props.className : ""}`}>
            <img src={prevBtn} alt="cross"/>
        </button>
    )
}
