export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

export function getMonth (month) {
  return months[month - 1];
}

export function getMonthReverce (month) {
  if(typeof month === 'number') return month;
  return months.indexOf(month) + 1;
}

export function getYears() {
  let years = [];

  for (let i = new Date().getFullYear(); i >= 1970; i--) {
      years.push(i);
  }

  return years;
}

export function formatDate(date){
  date = new Date(date);
  let month = date.toLocaleString('default', { month: 'long' });
  let day = date.getDate();
  let year = date.getFullYear();
  return `${month} ${day},${year}`;
}
