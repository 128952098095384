import React,{Component} from 'react'
import {observer} from "mobx-react";
import FileBlock from "../FileBlock";
import uploadIcon from "../../../Images/add-icon-gray.png";
import {SECTION} from "../../../constants/api";
import RootModel from "../../../Models/RootModel";
import {toJS} from "mobx";
import UploadModel from "../../../Models/loadModels/UploadModel";
import FileBlockCut from "../FileBlockCut";

@observer class FullPageDocuments extends Component {
  constructor(props) {
    super(props);
    this.uploadInputRef = React.createRef()
  }

  openFileDialog = (e, category) => {
    this.uploadInputRef.current.name = category
    this.uploadInputRef.current.click();
  }

  uploadFiles = (e) => {
    const currentSection = this.uploadInputRef.current.name
    const section = SECTION ? SECTION : currentSection
    let folder = RootModel.SubSectionMap[section.toLowerCase()];
    const files = e.target.files
    folder.info.files = Array.isArray(files) ? files : [files]
    UploadModel.handleFilesUpload(files, folder.section, folder.info.id, false, true)
    this.uploadInputRef.current.value = '';
    this.uploadInputRef.current.name = ''
  }

  render() {
    const {category,files,activeDocData,isInTrash,inOtherDeals,uploadBtn = true} = this.props
    return (
        <div
            className={'filesContainer ' + (category == 'General' && !files.length ? 'filesContainer_empty_width' : '')}
            key={category.items}>
              <span className='filesCategoryName'>
              {files[category].label}
                </span>
          <div className='fileWrapper'>
            {files[category].items.map((file) => {
              let isPreviewed = activeDocData ? (file.id.toString() === activeDocData.id.toString()) : null;
              return <FileBlockCut key={file.id}
                                   data={file}
                                   isPreviewed={isPreviewed}
                                   isInTrash={isInTrash}
                                   inOtherDeals={inOtherDeals}
                                   fileClassName={"file file-embed"}
                                   hideDownloadBtn={true}
              />
            })}
            {uploadBtn && <div className="uploadContainer" >
                      <span onClick={(e) => this.openFileDialog(e, category)}>
                        <img className='uploadImg' src={uploadIcon} alt="upload"/>
                      </span>
              <input style={{display: "none"}} multiple type="file"
                     onChange={e => {
                       this.uploadFiles(e)
                     }}
                     ref={this.uploadInputRef}/>
            </div>}
          </div>
        </div>
    )
  }

}
export default FullPageDocuments