import {decode} from "jszip/lib/base64";

export const UPLOADS = process.env.REACT_APP_BASE_URL;
export const API = UPLOADS + '/api/v1/';

export const OTHER_DEALS_FOLDER = 'Documents from Other Deals';

export const RESERVED_FILE_NAMES = ['trash', OTHER_DEALS_FOLDER]

export let DEAL_ID;

export let THREAD_ID;

export let MERCHANT_ID;

export let SECTION;

export let VIEW_FILE;

export let VIEW_PREVIEW = false;

export let PREVIEW_UPLOAD_INFO = {
  id: null,
  type: 'Deal',
  location: 'General',
  name: 'file',
}

export let FULL_PAGE;

export let LOAD_TYPE;

export const userData = setUserData();

function setUserData(){
  let href = (window.location.href).split('/?')[1];
  if(!href) return false;
  let props = href.split('&');
  let obj = {};
  props.forEach(prop => {
    prop = prop.split('=');
    if(prop[0] === 'view_file') {
      VIEW_FILE = prop[1];
    }
    if (prop[0] === 'view_preview') {
      VIEW_PREVIEW = prop[1] === 'true';
    }
    if(prop[0] === 'preview_upload_id') {
      PREVIEW_UPLOAD_INFO.id = prop[1];
    }
    if (prop[0] === 'preview_upload_type') {
      PREVIEW_UPLOAD_INFO.type = prop[1];
    }
    if (prop[0] === 'preview_upload_location') {
      PREVIEW_UPLOAD_INFO.location = prop[1];
    }
    if (prop[0] === 'preview_upload_name') {
      PREVIEW_UPLOAD_INFO.name = decodeURIComponent(decodeURIComponent(prop[1]));
    }
    if(prop[0] === 'section') {
      SECTION = prop[1];
    }
    if(prop[0] === 'funding') {
      FULL_PAGE = prop[1] === 'true';
    }
    if (prop[0] === 'thread_id') {
      THREAD_ID = prop[1];
      LOAD_TYPE = 'thread';
    } else if(prop[0] === 'merchant_id') {
      MERCHANT_ID = prop[1];
      LOAD_TYPE = 'merchant';
    } else if(prop[0] === 'deal_id') {
      LOAD_TYPE = 'deal'
      DEAL_ID = prop[1];
    }else{
      obj[prop[0]] = prop[1];
    }
  });

  delete obj.fullpage

  return {
    api_token: "9ab9355c-e011-418a-92f2-2f79af1d3a63",
    ...obj
  };
}
