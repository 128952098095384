import React from 'react';
import nextBtn from '../../Images/next-btn.png';

export default function NextBtn(props){
    return (
        <button onClick={props.onClick} disabled={props.disabled} className={`btn next-btn ${props.className ? props.className : ""}`}>
          <img src={nextBtn} alt="cross"/>
        </button>
    )
}
