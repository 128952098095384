import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';

import Banks from './Banks';
import SubDocs from './SubDocs';
import Contracts from './Contracts';
import Stips from './Stips';
import ViewAll from './ViewAll';

import NavModel from '../../Models/NavigationModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';
import PopUpModel from '../../Models/PopUpModel';

import DownloadBtn from '../buttons/DownloadBtn';
import ExpandBtn from '../buttons/ExpandBtn';

@observer class Toolbar extends Component {
  constructor(props){
    super(props);

    this.disposer = null;
    this.state = {
      activeTabId: null
    }
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const {activeTabId} = NavModel;
      this.setState({activeTabId});
    })

    this.disposer = mobx.reaction(() => PopUpModel.activePopUp,
    activePopUp => {
      this.setState({activePopUp});
    })
  }

  componentWillUnmount(){
    this.disposer();
  }

  openInNewWindow = () => {
    const { href } = window.location;
    window.open(href, "_blank");
  }

    render() {
      const {activeTabId, activePopUp} = this.state;
      const renderDownloadAll = new URLSearchParams(window.location.search).get("download_all");

      if(!activeTabId) return null;

        return (
          <div className="toolbar">
            <div className="nav">
              <div className="nav-buttons__container">
                <button onClick={e => {NavModel.setActiveTab('ViewAll')}} className={'view-all nav-btn ' + (activeTabId === 'ViewAll' ? 'active': '')}>View All</button>
                <button onClick={e => {NavModel.setActiveTab('Banks')}} className={'banks nav-btn ' + (activeTabId === 'Banks' ? 'active': '')}>Banks</button>
                <button onClick={e => {NavModel.setActiveTab('SubDocs')}} className={'sub-docs nav-btn ' + (activeTabId === 'SubDocs' ? 'active': '')}>Sub. Docs</button>
                <button onClick={e => {NavModel.setActiveTab('Stips')}} className={'stips nav-btn ' + (activeTabId === 'Stips' ? 'active': '')}>Stips</button>
                <button onClick={e => {NavModel.setActiveTab('Contracts')}} className={'contracts nav-btn ' + (activeTabId === 'Contracts' ? 'active': '')}>Contracts</button>
              </div>
              {renderDownloadAll && <DownloadBtn disabled={activePopUp === 'notification'} onClick={() => DownloadModel.downloadAllFilesInZip()}/>}
              <ExpandBtn onClick={this.openInNewWindow}/>
            </div>
            {activeTabId === 'Banks' && <Banks />}
            {activeTabId === 'SubDocs' && <SubDocs />}
            {activeTabId === 'Contracts' && <Contracts />}
            {activeTabId === 'Stips' && <Stips />}
            {activeTabId === 'ViewAll' && <ViewAll />}
          </div>
        )
    }
}

export default Toolbar
