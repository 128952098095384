import React, {Component} from 'react';
import DocumentPreview from "../documentPreview/DocumentPreview";
import {PDFObject} from "react-pdfobject";
import * as mobx from "mobx";
import FileBinModel from "../../Models/FileBinModel";
import RootModel from "../../Models/RootModel";
import DocPreviewModel from "../../Models/DocPreviewModel";
import TrashModel from "../../Models/TrashModel";
import {PREVIEW_UPLOAD_INFO, VIEW_FILE} from "../../constants/api";
import UploadModel from "../../Models/loadModels/UploadModel";
import uploadIcon from "../../Images/upload-icon.png";
import {Droppable} from "react-beautiful-dnd";
import SingleFileUploadSection from "./SingleFileUploadSection";
import banks from "./Banks";

class FileManagerPreviewFile extends Component {
    constructor(props) {
        super(props);

        this.disposer = null;
        this.uploadInputRef = React.createRef();

        this.state = {
            isInTrash: false,
            fileBinFiles: [],
            activeFolderId: 'FileBinFiles',
            bank: {},
        }
    }

    componentDidMount() {
        this.disposer = mobx.autorun(() => {
            const activeFolderId = FileBinModel.activeFolderId;

            const fileBinFiles = VIEW_FILE ? [...RootModel.FileBinFiles].filter(file => {
                    if(file.parent_id){
                        return file.parent_id.toString() === activeFolderId.toString()
                    }
                }) : [],
                activeDocData = DocPreviewModel.activeDocData,
                isInTrash = TrashModel.determineIsInTrash(),
                bank =  RootModel.Banks.find(x => x.id === PREVIEW_UPLOAD_INFO.id);
            this.setState({activeFolderId, fileBinFiles, activeDocData, isInTrash, bank});
        });
    }

    componentWillUnmount(){
        this.disposer();
    }

    render() {
        const { fileBinFiles, bank } = this.state;
        const file = fileBinFiles.length ? fileBinFiles[0] : null;

        if (!file) {
            if (!PREVIEW_UPLOAD_INFO.id) {
                return null;
            }

            return <div className="single-file-upload"> <Droppable droppableId={'FileBinFiles'}>
                {provided => <SingleFileUploadSection  provided={provided} data={bank} />}
            </Droppable></div>
        }

        return (
            <div className="PDFObject-wrapper"><PDFObject url={file.url} height="100vh"
                                                          pdfOpenParams={({pagemode: 'none', zoom: 'scale,left,top'})}/>
            </div>
        );
    }
}

export default FileManagerPreviewFile;