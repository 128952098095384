import { observable, action } from 'mobx';

class NavModel {
  @observable activeTabId = 'ViewAll';
    @observable isInit = true;
    @observable collapsedSections = {
      Banks: false,
      SubDocs: false,
      Contracts: false,
      Stips: false
    }
    @observable viewAllCollapsedSections = {
      Banks: false,
      SubDocs: false,
      Contracts: false,
      Stips: false
    }

    @action handleSectionCollapse(destination, viewAll){
      if(destination !== 'FileBinFiles'){
        if(viewAll){
          this.viewAllCollapsedSections[destination] = !this.viewAllCollapsedSections[destination];
        }else{
          this.collapsedSections[destination] = !this.collapsedSections[destination];
        }
      }
    }

    @action setActiveTab(id) {
      if (this.activeTabId === id) {
          return;
      }
      this.activeTabId = id;
    }

    getActiveTab() {
        return this.activeTabId;
    }
}

const model = new NavModel();

export default model;
