import { action, observable } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';

import RootModel from './RootModel';
import FileManagerModel from './FileManagerModel';
import FileBinModel from './FileBinModel';
import OtherDealsModel from './OtherDealsModel';
import UploadModel from './loadModels/UploadModel';
import PopUpModel from './PopUpModel';

import Helper from '../Helpers/helper';

class DropEndModel {
  @observable draggableData = null;

  @action setDraggableData(data){
      this.draggableData = data;
  }

  findSubSectionFiles(source, dest){
    if(dest === 'FileBinFiles'){
      return RootModel[dest];
    }
    for(let i = 0; i < RootModel[dest].length; i++){
      let subSection = RootModel[dest][i];
      if(source.droppableId.toString() === subSection.id.toString()){
        return subSection.files;
      }
    }

    return [];
  }

  @action sortInSubSection(result){
    const { source, destination, draggableId } = result,
          dest = (source.droppableId === 'FileBinFiles') ? 'FileBinFiles' : Helper.getSectionName(source.droppableId),
          file = Helper.findFile(source, draggableId);

    let subSectionFiles = this.findSubSectionFiles(source, dest);
    if(dest !== 'FileBinFiles') subSectionFiles.splice(destination.index, 0, file);
    else {
      let otherFile = subSectionFiles[destination.index];
      subSectionFiles.splice(destination.index, 1, file);
      subSectionFiles.splice(source.index, 1, otherFile)
    }

    if(dest !== 'FileBinFiles'){
      subSectionFiles.map((file, index) => {
        file.order = index;
      })
    }
  }

  @action dropBetweenSections(result){
    const { destination, source, draggableId } = result,
          startDest = source.droppableId === 'FileBinFiles' ? 'FileBinFiles' : Helper.getSectionName(source.droppableId),
          finishDest = destination.droppableId === 'FileBinFiles' ? 'FileBinFiles' : Helper.getSectionName(destination.droppableId),
          file = Helper.findFile(source, draggableId);

    FileManagerModel.addOneFile(file, finishDest, destination.droppableId, {startDest: startDest, startId: source.droppableId});
  }

  @action async copyFile(result){
    if(result.destination.droppableId.slice(0, 5) !== 'other'){
      NotificationManager.info('Copying started...')
      let dest = Helper.getSectionName(result.destination.droppableId);
      let file = OtherDealsModel.formatedDealsForPreview.find(deal => deal.id.toString() === result.draggableId.toString());

      let blob = await fetch( file.url).then(res => new Response(res.body)).then(response => response.blob())
      file = new File([blob], file.title);

      UploadModel.handleFilesUpload([file], dest, result.destination.droppableId, true)
    }
  }

  @action handleDropEnd(result) {
    if(!result.destination || PopUpModel.activePopUp === 'notification'){
      if(PopUpModel.activePopUp === 'notification') NotificationManager.info('Please wait for all files to be downloaded');
      return ;
    }else if(result.destination.droppableId === "FileBinFiles" && FileBinModel.activeFolderId.toString() === OtherDealsModel.otherDeals.folderId.toString()){
      NotificationManager.warning(`Can't be moved in this section`);
      return ;
    } else if(Helper.checkSectionIsDeprecated(result.destination.droppableId)){
      NotificationManager.warning(`Can't be moved in this section`);
      return;
    }

    const { destination, source } = result;

    if(source.droppableId.slice(0, 5) === 'other' ||
       result.source.droppableId === 'FileBinFiles' &&
       FileBinModel.activeFolderId.toString() === OtherDealsModel.otherDeals.folderId.toString()){
      return this.copyFile(result);
    }

    this.setDraggableData(result);
    if(destination.droppableId === source.droppableId) {// IN ONE SUBSECTION, SORT FILES CASE
      this.sortInSubSection(result);
    }else {// CASE WHEN WE DRAG FILE FROM ONE SECTION TO ANOTHER
      this.dropBetweenSections(result);
    }
  }

}

const model = new DropEndModel();
export default model;
