import React, { Component } from 'react';
import { observer } from 'mobx-react';
import autosize from 'autosize';

import UpdateModel from '../../Models/UpdateModel';

@observer class EditFileDescriptionForm extends Component {
    handleOnChange(e) {
        this.setState({
            description: e.target.value
        });
    }
    componentDidMount() {
        autosize(this.textarea);
        this.textarea.focus();
    }

    render() {
        return (
            <div className="edit-description-form">
                <textarea ref={node => this.textarea = node}
                onKeyUp={e => this.props.onChange(e)}
                className="text-field"
                cols="15" rows="1"
                placeholder="Please type a description here..">{this.props.description}</textarea>
            </div>
        )
    }
}

export default EditFileDescriptionForm;
