const creds = {
    accessKeyId: 'AKIAUO4QSSODPBC3ITPX',
    secretAccessKey: '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'
}

const configS3 = {
    bucketName: 'foxcapital',
    dirName: 'Fox',
    region: 'us-east-1',
    accessKeyId: creds.accessKeyId,
    secretAccessKey: creds.secretAccessKey
}


export { configS3 };
export default creds;