import React, { Component } from 'react';
import { observer } from 'mobx-react';

import DatePickerModel from '../../Models/DatePickerModel';

import DatePickerMonth from '../forms/DatePickerMonth';

@observer class DatePicker extends Component {

    handleSave = (e) => {
        let date = DatePickerModel.getActiveMonth();

        alert(date.name + ' ' + date.year);
    }

    render() {
        const { subSectionData, dest } = this.props;

        return (
            <div className="date-picker">
                {DatePickerModel.months.map(month => <DatePickerMonth dest={dest} subSectionId={subSectionData.id} key={month.id} data={month} />)}
            </div>
        )
    }
}

export default DatePicker;
