import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';

import PopUpModel from '../../Models/PopUpModel';
import AddBankAccountForm from '../popups/AddBankAccountForm';
import EditBankAccountForm from '../popups/EditBankAccountForm';
import AddFolderForm from '../popups/AddFolderForm';
import DeleteFileConfirmation from '../popups/DeleteFileConfirmation';
import UnzipConfirm from '../popups/UnzipConfirm';
import Loader from '../popups/Loader';

import ErrorScreen from '../popups/ErrorScreen';

@observer class PopUpContainer extends Component {
    constructor(props){
      super(props);

      this.disposer = null;
      this.state = {
          activePopUp: null
      }
    }

    componentDidMount(){
      this.disposer = mobx.autorun(() => {
        const {activePopUp, errorMessage} = PopUpModel;

        this.setState({activePopUp, errorMessage});
      })
    }

    componentWillUnmount(){
      this.disposer();
    }

    render() {
      const {activePopUp, errorMessage} = this.state;
      if(!activePopUp) return null;

      return (
          <React.Fragment>
            {activePopUp === 'addBankAccountForm' && <AddBankAccountForm />}
            {activePopUp === 'editBankAccountForm' && <EditBankAccountForm />}
            {activePopUp === 'addFolderForm' && <AddFolderForm />}
            {activePopUp === 'deleteFileConfirmation' && <DeleteFileConfirmation />}
            {activePopUp === 'unzipConfirm' && <UnzipConfirm />}
            {activePopUp === 'loader' && <Loader />}
            {activePopUp === 'error' && <ErrorScreen errorMessage={errorMessage}/>}
            {/*<NotificationContainer />*/}
          </React.Fragment>
      )
    }
}

export default PopUpContainer;
