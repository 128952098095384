import { observable, action } from 'mobx';
import RootModel from './RootModel';
import FileBinModel from './FileBinModel';
import OtherDealsModel from './OtherDealsModel';
import PopUpModel from './PopUpModel';

class DocPreviewModel {
  @observable activeDocData = null;
  @observable fullPreview = false;
  @observable showSelectMenu = false;
  @observable showMonth = false;
  @observable showYear = false;
  @observable selectedItem = {};
  @observable selectedTools = false;
  @observable currentFolder = null;
  @observable currentLocation = null;
  @observable subSectionId = null;
  @observable previewElementHeight = null;
  @observable isFullHeight = true;

  @observable isFirstFile = true;
  @observable isLastFile = true;

  @action toggleShow(isFullHeight) {
    this.isFullHeight = isFullHeight;
    this.isDeleteModeOn = false;
  };

  @action setPreviewElementHeight(height){
    this.previewElementHeight = height;
  }

  @action updateNav(source, file){
    let currentSubSection, {startDest, startId} = source;

    if(startDest === 'FileBinFiles'){
      currentSubSection = RootModel[startDest].filter(subSection => {
        return startDest.toString() === subSection.parent_id.toString()
      });
    }else{
      currentSubSection = RootModel[startDest].find(subSection => {
        return startId.toString() === subSection.id.toString()
      }).files;
    }

    currentSubSection = currentSubSection.filter(f => !f.isOtherDeal)

    let currentSubSectionFileIndex = currentSubSection.findIndex(subSectionFile => subSectionFile.id.toString() === file.id.toString());

    if(!currentSubSection.length > 1){
      this.activeDocData = null;
      this.toggleFullPreview();
    }else{
      if(currentSubSectionFileIndex !== 0){
        this.activeDocData = currentSubSection[currentSubSectionFileIndex - 1];
        if(currentSubSectionFileIndex - 1 === 0) this.isFirstFile = true;
        if(currentSubSectionFileIndex - 1 === currentSubSection.length - 1) this.isLastFile = true;
      }else if(currentSubSection[currentSubSectionFileIndex + 1]){
        this.activeDocData = currentSubSection[currentSubSectionFileIndex + 1];
        if(currentSubSection.length === 2) this.isFirstFile = true;
        if((currentSubSectionFileIndex + 1 === currentSubSection.length - 1)) this.isLastFile = true;
      }
    }
  }

  @action setDocData(data) {
    this.activeDocData = data.data;
    this.subSectionId = data.subSectionId;
  }

  @action setCurrentLocation(location) {
    this.currentLocation = location;
    this.isFullHeight = (location !== 'FileBinFiles');
  }

  @action togglePreview(action, data) {
    if (action === 'close' || !this.currentLocation) {
      this.activeDocData = null;
      this.fullPreview = false;
      return;
    }

    this.activeDocData = data ? data : this.activeDocData;
  };

  @action toggleFullPreview(data, isSort) {
    if (!this.currentLocation || !this.activeDocData) {
      this.fullPreview = false;
      return;
    }

    this.fullPreview = !this.fullPreview;

    if(!this.fullPreview) {
      this.activeDocData = null;
      return ;
    }

    if (this.activeDocData && !this.activeDocData.url && data) this.activeDocData.url = data.url;

    let currentSubSection;

     if(this.currentLocation === 'FileBinFiles'){
       if(FileBinModel.activeFolderId.toString() === OtherDealsModel.otherDeals.folderId.toString()){
          currentSubSection = OtherDealsModel.formatedDealsForPreview;
       }else{
         currentSubSection = RootModel[this.currentLocation].filter(file => file.parent_id.toString() === FileBinModel.activeFolderId.toString())
       }
     }else{
        currentSubSection = RootModel[this.currentLocation].find(subSection => this.subSectionId.toString() === subSection.id.toString());
        currentSubSection = currentSubSection ? currentSubSection.files : [];
     }

    let currentSubSectionFileIndex = currentSubSection.findIndex(file => this.activeDocData.id.toString() === file.id.toString());

    this.isFirstFile = (currentSubSectionFileIndex === 0);
    this.isLastFile = (currentSubSectionFileIndex === currentSubSection.length - 1);
  }

  @action documentLoad() {
    this.isDocumentLoad = true;
  }

  @action toggleMenu() {
    this.showSelectMenu = !this.showSelectMenu;
  }

  @action toggleYear() {
    this.showYear = !this.showYear
  }

  @action toggleMonth() {
    this.showMonth = !this.showMonth
  }

  @action toggleSelectedTools(toggle) {
    this.selectedTools = toggle || !this.selectedTools;
  }

  @action selectFileLocation(obj) {
    this.selectedItem = obj;
    this.showSelectMenu = false;
    if(!this.currLocation && obj) this.currLocation = obj.label;
  }

  @action getPrevFile() {
    let currentSubSection, currentSubSectionFileIndex;
    if (this.currentLocation !== "FileBinFiles") {

      currentSubSection = RootModel[this.currentLocation].find(subSection => this.subSectionId.toString() === subSection.id.toString()).files;
      currentSubSectionFileIndex = currentSubSection.findIndex(file => this.activeDocData.id.toString() === file.id.toString());
      this.activeDocData = (currentSubSectionFileIndex > 0) ? currentSubSection[currentSubSectionFileIndex - 1] : this.activeDocData;

      this.isLastFile = (currentSubSectionFileIndex - 1 === currentSubSection.length - 1);
    } else {
      let files = RootModel.FileBinFiles.filter(f => !f.isOtherDeal);

      this.currentFolder = files.filter(file => file.parent_id.toString() === this.activeDocData.parent_id.toString());
      currentSubSectionFileIndex = this.currentFolder.indexOf(this.activeDocData);
      this.activeDocData = (currentSubSectionFileIndex === 0) ? this.activeDocData : this.currentFolder[currentSubSectionFileIndex - 1];

      this.isLastFile = (currentSubSectionFileIndex - 1 === this.currentFolder - 1);
    }


    this.isFirstFile = (!this.activeDocData || currentSubSectionFileIndex - 1) === 0;
  }

  @action getNextFile() {
    let currentSubSection, currentSubSectionFileIndex;

    if (this.currentLocation !== "FileBinFiles") {
      currentSubSection = RootModel[this.currentLocation].find(subSection => this.subSectionId.toString() === subSection.id.toString()).files;
      currentSubSectionFileIndex = currentSubSection.findIndex(file => this.activeDocData.id.toString() === file.id.toString());

      this.activeDocData = (currentSubSectionFileIndex === currentSubSection.length - 1) ? this.activeDocData :
        currentSubSection[currentSubSectionFileIndex + 1];

      this.isLastFile = (currentSubSectionFileIndex + 1 === currentSubSection.length - 1);
    } else {
      let files = RootModel.FileBinFiles.filter(f => !f.isOtherDeal);

      this.currentFolder = files.filter(file => file.parent_id === this.activeDocData.parent_id)
      currentSubSectionFileIndex = this.currentFolder.indexOf(this.activeDocData);
      this.activeDocData = (currentSubSectionFileIndex === this.currentFolder.length - 1) ? this.activeDocData : this.currentFolder[currentSubSectionFileIndex + 1];

      this.isLastFile = (currentSubSectionFileIndex + 1 === this.currentFolder.length - 1);
    }

    this.isFirstFile = (!this.activeDocData || (currentSubSectionFileIndex + 1) === 0);
  }

  @action deleteCurrentFile() {
    PopUpModel.open('deleteFileConfirmation', {
      fileData: this.activeDocData,
      dest: 'FileBinFiles',
      subSectionId: null,
      isFromReader: true
    });
  }

  @action deleteCurrentFileAfterConfirm() {
    if (this.currentLocation === 'FileBinFiles') {
      this.currentFolder = RootModel.FileBinFiles.filter(file => file.parent_id.toString() === this.activeDocData.parent_id.toString());
    } else {
      this.currentFolder = RootModel[this.currentLocation].find(subSection => subSection.id.toString() === this.subSectionId.toString()).files;
    }

    if (this.currentFolder.length > 1) {
      let wasFirst = this.isFirstFile;
      if (this.isLastFile) {
        this.getPrevFile();
        this.isLastFile = true;
      } else {
        this.getNextFile();
        this.isFirstFile = wasFirst;
      }
    } else {
      this.togglefullPreview();
      this.activeDocData = null;
    }
  }
}

const model = new DocPreviewModel();

export default model;
