import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uploadIcon from '../Images/upload-icon.png';

import UploadModel from '../Models/loadModels/UploadModel';

@observer class UploadSubsection extends Component {
    render() {
        const { subSectionData, dest, additionalData = null } = this.props;
        const destinationClass = additionalData && additionalData.parentDest ? additionalData.parentDest : dest;
        return (
            <div className={`upload-section ${destinationClass}`}>
                <input type="file" multiple onChange={e => UploadModel.handleFilesUpload(e.target.files, dest, subSectionData.id, false, false, additionalData)} className="upload-section__input"/>
                <span className="upload-section__icon"><img src={uploadIcon} alt=""/></span>
                <span className="upload-section__text">Drag files here or click <span className="underline">here</span> to upload</span>
            </div>
        )
    }
}

export default UploadSubsection;
