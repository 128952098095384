import { observable, action } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';

import FileManagerModel from './FileManagerModel';
import FileBinModel from './FileBinModel';
import PopUpModel from './PopUpModel';
import OtherDealsModel from './OtherDealsModel';
import TrashModel from './TrashModel';

import fetchData from '../Services/fetchData';
import Helper from '../Helpers/helper'
import {
  API,
  DEAL_ID,
  userData,
  THREAD_ID,
  LOAD_TYPE,
  SECTION,
  FULL_PAGE,
  VIEW_PREVIEW,
  MERCHANT_ID,
} from '../constants/api';
import axios from 'axios';

import {
  Sections,
  BankDateToMonth,
  SubDocStructure,
  ContractStructure,
  StipStructure,
  getSubsectionMaps,
  BankSubSectionStructure
} from './Structures';
import uuid from "uuid";
import {root} from "eslint-config-react-app";



class RootModel {
  @observable Banks = [BankDateToMonth];
  @observable SubDocs = SubDocStructure;
  @observable Contracts = ContractStructure;
  @observable Stips = StipStructure;
  @observable FileBinFiles = [];
  @observable SubSectionMap = getSubsectionMaps();
  @observable BanksInitialSubSections = BankSubSectionStructure;
  @observable BanksSubSections = [];

  @action async getBanks(init){
      if(init) PopUpModel.open('loader');

      let reqData = JSON.stringify({
          ...userData,
          deal_id: DEAL_ID
        })

      axios.post(`${API}bank_accounts/index_with_documents`, reqData, {
        headers: {
          'Content-Type': 'text/plain'
        }
      })
        .then(action(res => {
          res = res.data;
          this.BanksSubSections = [];

          let banks = res.map(bank => {
            Sections['Banks'].push(`${bank.redacted_account_number}&${bank.id}`)
            return {
              name: bank.name,
              accountNumber: bank.redacted_account_number,
              id: bank.id,
              isDeletable: true,
              isCollapsed: false,
              isDatePickerShown: false,
              bankDetails: bank.bank_account_details,
              files: this.formatBankDocumentsToFiles(bank), //takes files from banks' details array
              subSections: this.formatBankDocumentsToSubSections(bank) // takes files from bank and places in its section
          }})

          this.setBanks(banks, init);

          PopUpModel.closeAll();

          return res;
        }) )
        .catch(err => {
          if(err.response){
            PopUpModel.open('error', `${err.response.status}: ${err.response.statusText}`);
          }else{
            PopUpModel.open('error', `Error: ${err.message}`);
          }
          NotificationManager.error('Can not load bank\'s information');
        })
    }

  @action setBanks(banks, init){
    this.Banks = [this.Banks[0], ...banks];
    banks.map(bank => this.sortFilesByDate(bank.id))
  }

  formatBankDocumentsToFiles(bank){
    let files = [];
    bank.bank_account_details.map(detail => {
      detail.documents.map((document, index) => {
        // TrashModel.deleteFileFromTrash(document)
        files.push( Helper.convertToFileObject(document, detail.month, detail.year) );
        return index;
      })
      return detail;
    });
    return files;
  }

  formatBankDocumentsToSubSections(bank){
    BankSubSectionStructure.forEach(subSection => {

      let newSubSection = {
        ...subSection,
        id: uuid.v4(),
        bankId: bank.id,
        files: bank.documents.filter(doc => doc.document_type === subSection.dest).map(doc => Helper.convertToFileObject(doc)),
        parentDest: 'Banks',
        parentSectionId: bank.id,
      }
      this.BanksSubSections = [newSubSection, ...this.BanksSubSections];
    })
    return this.BanksSubSections.filter(x => x.bankId === bank.id);
  }

  @action async getFolderDocuments(isUpdating, parentId, unZippedFolder){
    let wasSubfoldersRequest = this.FileBinFiles.find(file => {
      if(file.parent_id) return (file.parent_id.toString() === FileBinModel.activeFolderId.toString());
    });

    if( wasSubfoldersRequest && !isUpdating && ( FileBinModel.activeFolderId.toString() !== TrashModel.trashId.toString() ) ||
      (!isUpdating && FileBinModel.activeFolderId === 'FileBinFiles' && !unZippedFolder) ||
       FileBinModel.activeFolderId === OtherDealsModel.otherDeals.folderId) return null;

    let data = {
      parent_id: parentId || FileBinModel.activeFolderId,
      parent_type: "Folder"
    }

    let documents = await fetchData('documents', data)
    .then(res => res.json())
    .then(action(res => {
      let files = res.map(file => {
        // TrashModel.deleteFileFromTrash(file)
        let fileObj = Helper.convertToFileObject(file, file.month, file.year);
        if(!fileObj.parent_id){
          fileObj.parent_id = (data.parent_id) ? data.parent_id : +data.document_type;
        }
        return fileObj;
      })

      if( FileBinModel.activeFolderId.toString() === TrashModel.trashId.toString() ){
        files = files.forEach(file => {
          let exist = this.FileBinFiles.find(fileBinFile => fileBinFile.id.toString() === file.id.toString());
          if(!exist) this.FileBinFiles.push(file);
        })
      }

      if(!isUpdating && ( FileBinModel.activeFolderId.toString() !== TrashModel.trashId.toString() ) ){
         this.FileBinFiles = this.FileBinFiles.concat(files);
      }

      return files;
    }) )
    .catch(err => {
      NotificationManager.error('Can not load files of this folder');
    })

    return documents;
  }

  determineDocumentType(documentType, document){
    if(documentType === 'Signed_contract_coj' || documentType === 'Contracts'){
      return {section: 'Contracts', i: 0};
    }

    for(let section in Sections){
      if(documentType !== 'FileBinFiles'){
        for(let i = 0; i < Sections[section].length; i++){
          if(documentType && typeof Sections[section][i] !== 'object' && documentType.toLowerCase() === Sections[section][i].toLowerCase()){
            return {section, i};
          }
        }
      }
    }

    return 'general';
  }

  @action sortOtherDocuments(data, otherDealsSort){
      data.map((document, index) => {
        //isSection: we are loading a single document_type view
        let isSection = (document.document_type && SECTION && SECTION.length && SECTION.toLowerCase() == document.document_type.toLowerCase()) || FULL_PAGE || VIEW_PREVIEW;

        let isGeneralOrOther = (document.document_type && (document.document_type.toLowerCase() === 'general' || document.document_type === 'other_contract_docs'))

        let type = (isGeneralOrOther || (isSection)) ? document.document_type : this.determineDocumentType(document.document_type, document);

        let file = Helper.convertToFileObject(document, document.month, document.year);

        if (!type || typeof type == 'string') {
          if (type === 'other_contract_docs') {
            file.parent_id = OtherDealsModel.otherDeals.folderId;
            this['FileBinFiles'].push(file);
            OtherDealsModel.updateDates(file);
          } else {
            FileManagerModel.addOneFile(file, 'FileBinFiles', type);
          }
        } else if (type && type.section && typeof type.i === 'number') {
          if (otherDealsSort) {
            if (this[type.section][type.i].otherDeals) {
              this[type.section][type.i].otherDeals.push(file);
            }
          } else {
            this[type.section][type.i].files.push(file);
          }
        }

        return index;
      })
  }



  @action async getDocuments(isUpdating){
    let data = {};
    let fetchUrl = 'documents';
    switch (LOAD_TYPE) {
      case 'thread': {
        data.thread_id = THREAD_ID;
        fetchUrl += '/get_thread';
        break;
      }
      case 'merchant': {
        data.merchant_id = MERCHANT_ID;
        fetchUrl += '/get_merchant';
        break;
      }
      case 'deal': {
        data.parent_id = DEAL_ID;
        data.parent_type = 'Deal';
        break;
      }
      default: {
        break;
      }
    }

    if(!isUpdating) PopUpModel.open('loader');

    let documents = await fetchData(fetchUrl, data)
    .then(res => res.json())
    .then(res => {

      if(res.error) return null;
      if(!isUpdating){
        this.sortOtherDocuments(res);
      }
      return res;
    })
    .catch(err => {
      PopUpModel.open('error', err);
      NotificationManager.error('Can not load files');
    })

    if(PopUpModel.activePopUp !== 'error') PopUpModel.closeAll();
    return documents;
  }

  @action updateSectionFiles(file, destination, subSectionId, source, parentDestination, parentSectionId){
    const rootDestination = parentDestination || destination;
    const rootSectionId = parentDestination && parentSectionId ? parentSectionId : subSectionId;

    for(let i = 0; i < this[rootDestination].length; i++){
      if(this[rootDestination][i].id.toString() === rootSectionId.toString()){
        if (parentDestination) {
          this[parentDestination][i].subSections.forEach((subSection) => {
            if (subSection.id === subSectionId) {
              subSection.files.push(file);
            }
          })
        } else {
          this[destination][i].files.push(file);
        }
        if(source) this.deleteFileFromSection(file, source);
        return;
      }
    }
  }

  @action deleteFileFromSection(file, source){
    if(source === 'FileBinFiles'){
      for(let i = 0; i < this[source].length; i++){
          if(this[source][i].id.toString() === file.id.toString()){
            this[source].splice(i, 1);
            break;
          }
      }
    }else{
      for(let i = 0; i < this[source].length; i++){
        let subSection = this[source][i];
        for(let j = 0; j < subSection.files.length; j++){
          let deletebleFile;
          deletebleFile = subSection.files[j];
          if(deletebleFile.id.toString() === file.id.toString()){
            subSection.files.splice(j, 1);
            if (subSection.parentDest && root[subSection.parentDest] && root[subSection.parentDest].subSections) {
              root[subSection.parentDest].subSections.forEach(section => {
                if (section.id.toString() === subSection.id.toString()){
                  subSection.files.splice(j, 1);
                }
              })
            }
            break;
          }
        }
      }
    }
  }

  @action sortFilesByDate(subSectionId) {
      let newSection = this.Banks.map(subSection => {
          if(subSection.id.toString() === subSectionId.toString()) {
              let files = subSection.files;

              let sortedFiles = files.slice().sort((a,b) => {
                let aDate = new Date(`${a.year} ${a.month}`);
                let bDate = new Date(`${b.year} ${b.month}`);

                return bDate - aDate;
              });

              sortedFiles.map((file, index) => {
                  file.order = index + 1;

                  return file;
              });

              subSection.files = sortedFiles;
          }
          return subSection;
      });

      this.Banks = newSection;
  };

}//END

const model = new RootModel();
model.Banks.forEach(subSection => model.sortFilesByDate(subSection.id));

export default model;
