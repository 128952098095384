import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { months, getYears, getMonth } from "../../Helpers/convertData";

import FileManagerModel from '../../Models/FileManagerModel';
import UpdateModel from '../../Models/UpdateModel';
import SubmitBtn from '../buttons/SubmitBtn';

@observer class EditFileDateForm extends Component {
  constructor(props){
    super(props);
    this.YEARS = getYears();
  }

    render() {
        const { fileData, subSectionId, defaultMonth, defaultYear } = this.props;
        
        return (
            <div className="edit-date-form">
                <select className="month" value={defaultMonth} onChange={e => this.props.handleMonthChange(e)}>
                    {months.map(month => <option key={month} value={month}>{month}</option>)}
                </select>
                <select className="years" value={defaultYear} onChange={e => this.props.handleYearChange(e)}>
                    {this.YEARS.map(year =>
                      <option key={year} value={year}>{year}</option>)}
                </select>
            </div>
        )
    }
}

export default EditFileDateForm;
