import React from 'react';

import Banks from './Banks';
import SubDocs from './SubDocs';
import Contracts from './Contracts';
import Stips from './Stips';

function ViewAll(props){
    return (
        <div className="toolbar__container all">
          <Banks viewAll={true} /> <div className="toolbar__container-divider"/>
          <SubDocs viewAll={true} /> <div className="toolbar__container-divider"/>
          <Stips viewAll={true} /> <div className="toolbar__container-divider"/>
          <Contracts viewAll={true} />
        </div>
    )
}

export default ViewAll;
