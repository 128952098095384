import React, { Component } from 'react';
import { observer } from 'mobx-react';
import uuid from 'uuid';
import mammoth from 'mammoth';
import PropTypes from 'prop-types';
import * as mobx from 'mobx';
import { PDFObject } from 'react-pdfobject';
import folderIcon from '../../Images/folder.png';

import Helper from '../../Helpers/helper';
import DocPreviewModel from '../../Models/DocPreviewModel';
import FileManagerModel from '../../Models/FileManagerModel';
import UploadModel from '../../Models/loadModels/UploadModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';

import DownloadBtn from './../buttons/DownloadBtn';
import FullPrevBtn from './../buttons/FullPrevBtn';
import CloseBtn from '../buttons/CloseBtn';
import Button from './../buttons/Button';
import DownloadFileMessage from './DownloadFileMessage';


@observer class DocPreview extends Component {
  constructor(props){
    super(props);

    this.docRef = React.createRef();

    this.disposer = null;
    this.state = {
      loading: true,
      mousePosition: null,
      elementHeight: DocPreviewModel.previewElementHeight || null
    }
  }

  static propTypes = {
    disableFullPreview: PropTypes.bool
  };

  static defaultProps = {
    disableFullPreview: false
  };

  onError = (e) => {
    console.log('DocPreview error',e)
  }

  handleMouseMove = (e) => {
    let previewedDocument = this.docRef.current;
    let managerHeight = document.querySelector('.file-manager__container').offsetHeight;
    let newHeight = managerHeight - e.pageY;

    DocPreviewModel.setPreviewElementHeight(newHeight);
    this.setState({elementHeight: newHeight})
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const {activeDocData} = DocPreviewModel,
            {isFullHeight} = DocPreviewModel;
      DocPreviewModel.selectFileLocation(null);


      if(activeDocData && activeDocData.type === 'DOCX'){
          let newURL =  activeDocData.url;//'https://cors-anywhere.herokuapp.com/' +


          const jsonFile = new XMLHttpRequest();
          jsonFile.open('GET', newURL, true);
          jsonFile.send();
          jsonFile.responseType = 'arraybuffer';
          jsonFile.onreadystatechange = () => {
            if (jsonFile.readyState === 4 && jsonFile.status === 200) {
              mammoth.convertToHtml(
                { arrayBuffer: jsonFile.response },
                { includeDefaultStyleMap: true },
              )
              .then((result) => {
                const docEl = document.createElement('div');
                docEl.className = 'document-container';
                docEl.innerHTML = result.value;
                document.getElementById('docx').innerHTML = docEl.outerHTML;
              })
              .catch((a) => {
                console.error(a);
              })
              .done();
            }
          };

      }

      this.setState({activeDocData, isFullHeight});
    })
  }

  handleResize(e){
    document.addEventListener('mousemove', this.handleMouseMove);
  }

  handleResizeSubmit(e){
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  componentWillUnmount(){
    this.disposer();
    DocPreviewModel.selectFileLocation(null);
    document.removeEventListener('mousemove', this.handleMouseMove);
  }

  resizePreviewByButton = (e, isFullHeight) => {
    this.setState({elementHeight: false}, DocPreviewModel.toggleShow(isFullHeight));
  }

  render() {
    const {activeDocData, isFullHeight, elementHeight} = this.state,
          {disableFullPreview} = this.props;

    if(!activeDocData) return null;

    let isImage = (activeDocData.type === "PNG" || activeDocData.type === "JPEG" || activeDocData.type === "JPG");

    return (
      <div className={`file-manager__preview ${isFullHeight ? 'full-height' : ''}`} ref={this.docRef} style={elementHeight ? {height: elementHeight, bottom: elementHeight} : {}}>
        <div className="adjust-area" onMouseDown={(e) => this.handleResize(e)} onMouseUp={(e) => this.handleResizeSubmit(e)}></div>
        <div className="file-manager__preview__toggle-area">
          <div className="head-section" ref={node => this.headSectionPreview = node}>
              <Button type='FileBinBtn' onClick={(e) => this.resizePreviewByButton(e, false)} />
              <Button type='ResizeBtn' onClick={(e) => this.resizePreviewByButton(e, true)} />
              <CloseBtn onClick={() => DocPreviewModel.togglePreview('close')} />
          </div>
        </div>
        <div className="wrapper" id="preview-wrapper">
        { activeDocData.type && isImage && <img src={activeDocData.url} alt={activeDocData.name} key={uuid.v4()}/>}
        { (!activeDocData.type || (activeDocData.type && (activeDocData.type === "PDF"))) && <div className="PDFObject-wrapper"><PDFObject url={activeDocData.url} page={1}/></div> }
        { activeDocData.type && activeDocData.type === "DOCX" && <div className="pg-viewer-wrapper"><div id="docx"/></div> }
        { activeDocData.type && activeDocData.type !== "DOCX" && !isImage && activeDocData.type !== "PDF" && <DownloadFileMessage /> }
        </div>
        {!disableFullPreview && <div className="head-section">
          <Button type='FullPrevBtn' onClick={(e, clickedIcon) => DocPreviewModel.toggleFullPreview(e)}/>
        </div>}
      </div>
    )
  }
}

export default DocPreview;
