import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx'
import uuid from 'uuid';
import { Draggable } from 'react-beautiful-dnd';

import FileBlock from './FileBlock';
import OtherDealsModel from '../../Models/OtherDealsModel';
import Helper from '../../Helpers/helper';
import { formatDate } from '../../Helpers/convertData';

@observer class OtherDealsFolder extends Component{
  constructor(props){
    super(props);

    this.state = {}
    this.disposer = null;
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const { otherDealsDocuments } = OtherDealsModel;
      OtherDealsModel.determineInOtherDeals(true);

      this.setState({ otherDealsDocuments })
    })
  }

  componentWillUnmount(){
    this.disposer();
    OtherDealsModel.determineInOtherDeals(false);
  }

  render(){
    const { otherDealsDocuments } = this.state;
    if(!otherDealsDocuments) return null;

    return (<div className="other-deal-folder">
      {otherDealsDocuments.map((deal, index) => {
        if(deal.documents.length){
          return <div key={uuid.v4()}>
            <p className="other-deals-date">{formatDate(deal.submission_date)}</p>
            <div className="files-section">
              {deal.documents.map(doc => {
                let file = Helper.convertToFileObject(doc);
                return <Draggable draggableId={file.id} index={index} key={file.id}>
                          {provided => <FileBlock provided={provided} key={file.id} data={file} isPreviewed={false} />}
                       </Draggable>
              })}
            </div>
          </div>;
        }
      })}
    </div>);
  }
}

export default OtherDealsFolder;
