import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import * as mobx from 'mobx';

import arrowUpIcon from '../../Images/arrow-up.png';

import FileManagerModel from '../../Models/FileManagerModel';
import DocPreviewModel from '../../Models/DocPreviewModel';

import FileBin from '../fileManager/fileBin/FileBin';
import DocPreview from '../documentPreview/DocumentPreview';

@observer class FileManager extends Component {
  constructor(props){
    super(props);

    this.disposer = null;
    this.state = {
      init: true
    }
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const { activeDocData } = DocPreviewModel;

      this.setState({activeDocData})
    })
  }

  setInitFalse(){
    this.setState({init: false})
  }

  componentWillUnmount(){
    this.disposer();
  }

  render() {
    const {init, activeDocData} = this.state;

    return (
      <div className={`file-manager`}>
        <Droppable droppableId={'FileBinFiles'}>
            {provided => <FileBin provided={provided} />}
        </Droppable>
        {activeDocData && <DocPreview />}
      </div>
    )
  }

}

export default FileManager;
