import React, { Component, Fragment } from 'react'
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import uuid from 'uuid';

import DocPreviewModel from '../../Models/DocPreviewModel';
import RootModel from '../../Models/RootModel';
import FileBinModel from '../../Models/FileBinModel';
import OtherDealsModel from '../../Models/OtherDealsModel';
import PopUpModel from '../../Models/PopUpModel';
import FileManagerModel from '../../Models/FileManagerModel';
import UploadModel from '../../Models/loadModels/UploadModel';
import DownloadBtn from '../buttons/DownloadBtn';
import DeleteBtn from '../buttons/DeleteBtn';

import PrevBtn from '../buttons/PrevBtn';
import NextBtn from '../buttons/NextBtn';
import CloseBtn from '../buttons/CloseBtn';

import Helper from '../../Helpers/helper';
import {getMonth} from '../../Helpers/convertData';
import {monthsByIndex, monthsByName} from '../../constants/date'

import selectDownBtn from '../../Images/select-down-btn.png';
import addIcon from '../../Images/plus-icon.png';

@observer class MovingSelect extends Component {
  constructor(props){
    super(props);
    this.disposer = null;//needed for mobx.autorun, it's ret function and than you can delete disposer(compWillUnmount) to prevent memory leak

    let date = new Date();

    let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    let years = [];
    let dates = Array(12)
                .fill(null)
                .map((_) => { let newdate = date.setMonth(date.getMonth() - 1); return Helper.formatSelectDate(newdate); });    
    
    for (let i = date.getFullYear(); i >= 1970; i--) {
      years.push(i);
    }

    let label = this.initLabel();

    let defaultDate = new Date();
    defaultDate.setMonth(defaultDate.getMonth() - 3);

    let defaultMonth = DocPreviewModel.activeDocData.month ? getMonth(DocPreviewModel.activeDocData.month) : months[defaultDate.getMonth()];
    let defaultYear = DocPreviewModel.activeDocData.year ? DocPreviewModel.activeDocData.year : defaultDate.getFullYear();

    this.state = {
      dates: dates,
      months: months,
      years: years,
      defaultMonth,
      defaultYear,
      label: null,
      selectedTools: false,
      selectFiles: null
    }
  }

  initLabel(){
    let obj;
    if(DocPreviewModel.currentLocation && RootModel[DocPreviewModel.currentLocation]){
      for(let i = 0; i < RootModel[DocPreviewModel.currentLocation].length; i++){
        let item = RootModel[DocPreviewModel.currentLocation][i];
        if(DocPreviewModel.subSectionId && DocPreviewModel.subSectionId.toString() === item.id.toString()){
          obj = {
            label: DocPreviewModel.currentLocation,
            class: DocPreviewModel.currentLocation.toLowerCase() + "-label",
            item: item,
            parent: DocPreviewModel.currentLocation
          }
          if(!DocPreviewModel.selectedItem) DocPreviewModel.selectFileLocation(obj);
          return obj;
        }
      }
    }

    return '';
  }

  handleYearChange = (e) => {
    const {label, obj} = this.state;
    this.setState({
      defaultYear: +e.target.value,
    });
    if(label === 'Banks'){
      obj.year = +e.target.value;
      DocPreviewModel.selectFileLocation(obj);
    }
  }

  handleMonthChange = (e) => {
    const {label, obj} = this.state;

    this.setState({
      defaultMonth: e.target.value
    });
    if(label === 'Banks'){
      obj.month = e.target.value;
      DocPreviewModel.selectFileLocation(obj);
    }
  }


  handleDateChange = (e) => {
    const { label, obj } = this.state;
    const { month, year } = this.state.dates.find(option => option.month === +e.target.value)

    this.setState({
      defaultMonth: monthsByIndex[month],
      defaultYear: year,
    });

    if(label === 'Banks'){
      obj.month = monthsByIndex[month];
      obj.year = year;
      DocPreviewModel.selectFileLocation(obj);
    }
  }

  handleBankChange = (e) => {
    const {Banks, obj} = this.state;
    const newSubSection = Banks.find(b => b.id.toString() === e.target.value).subSections.find(x => x.dest === obj.item.dest)
    obj.bankId = newSubSection.bankId;
    obj.id = newSubSection.id;
    obj.parentSectionId = newSubSection.bankId;
    DocPreviewModel.selectFileLocation(obj);
  }

  moveFile(){
    FileManagerModel.addOneFile(this.state.activeDocData, DocPreviewModel.selectedItem.parent, DocPreviewModel.selectedItem.item.id, {startDest: DocPreviewModel.currentLocation, startId: DocPreviewModel.subSectionId}, DocPreviewModel.selectedItem.parentSection, DocPreviewModel.selectedItem.parentSectionId);
    this.setState({label: null, selectedTools: false});
    
    if(this.state.isLastFile) return;
    DocPreviewModel.getNextFile();
  }

  handeSelectSection(obj){
    const {defaultMonth, defaultYear, Banks, selectedTools} = this.state;

    if(obj.label === 'Banks'){
      obj.month = defaultMonth;
      obj.year = defaultYear;
    }

    if (obj.label === 'BanksSub') {
      const newSubSection = Banks.filter(b => !!b.accountNumber)[0].subSections.find(x => x.dest === obj.item.dest)
      obj.bankId = newSubSection.bankId;
      obj.id = newSubSection.id;
      obj.parentSectionId = newSubSection.bankId;
    }

    const selectFiles = obj.item.files.reduce((acc, file) => {
      if (file) { acc.push({ year: file.year, month: file.month }) }
      return acc;
    }, []);

    const dates = this.state.dates.map((date) => {
      if (selectFiles.some(({ year, month }) => year === date.year && month === date.month)) {
        return {...date, onFile: true};
      }
      return {...date, onFile: false};
    });

    this.setState({label: obj.label, obj: obj, selectedTools: true, selectFiles, dates});
    DocPreviewModel.selectFileLocation(obj);
  }

  closeSelectedTools(){
    this.setState({selectedTools: false});
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const {activeDocData, isFirstFile, isLastFile, selectedItem, currentLocation} = DocPreviewModel;
      const itemLabel = DocPreviewModel.selectedItem ? DocPreviewModel.selectedItem.label : this.initLabel();
      const {activeFolderId} = FileBinModel, {folderId} = OtherDealsModel.otherDeals;

      const isMonthToDate = (selectedItem && selectedItem.item.id == RootModel.Banks[0].id);

      const {Banks, SubDocs, Contracts, Stips, BanksInitialSubSections} = RootModel;

      let inOtherDeals = currentLocation === 'FileBinFiles' && activeFolderId.toString() === folderId.toString();

      this.setState({Banks, SubDocs, Contracts, Stips, BanksInitialSubSections, isMonthToDate,
                    activeDocData, isFirstFile, isLastFile,
                    selectedItem, itemLabel, inOtherDeals})
    })

    this.disposer = mobx.reaction(() => DocPreviewModel.selectedTools,
    (selectedTools) => {
      this.setState({selectedTools});
    })

    this.disposer = mobx.reaction(() => DocPreviewModel.currentLocation,
    (currentLocation) => {
      this.initLabel();
    })

  }

  componentWillUnmount(){
    DocPreviewModel.selectFileLocation(null);
    this.disposer();
  }

  render() {
    const {inOtherDeals, activeDocData, Banks, SubDocs, Contracts, Stips, BanksInitialSubSections,
           defaultMonth, defaultYear, months, years, selectedItem, isMonthToDate,
           isFirstFile, isLastFile, label, itemLabel, selectedTools, dates } = this.state;
    if(inOtherDeals || (!Banks || !SubDocs || !Contracts || !Stips || !BanksInitialSubSections)) return null;

    return (<Fragment>
      <div className="file-location" onClick={() => DocPreviewModel.toggleMenu()}>
        {itemLabel && DocPreviewModel.selectedItem ? (<div className="row">
          <span className={`label ${(selectedItem && selectedItem.class) ? selectedItem.class : ''}`}>{itemLabel}</span>
          {itemLabel === "Banks" ?
            <div>{Helper.shortenName(`${selectedItem.item.name} ${!!(+selectedItem.item.accountNumber) ? `| ${selectedItem.item.accountNumber}` : ""}`)}</div>
            : <div>{Helper.shortenName(`${selectedItem.item.title} ${selectedItem.item.account_number ? `| ${selectedItem.item.account_number}` : ""}`)}</div>
          }
        </div>)
          : (
            <span>Select File Location</span>
          )}
        <span className="select-btn">
          <img src={selectDownBtn} alt="select-menu" className={DocPreviewModel.showSelectMenu === false ? "open" : "close"} />
        </span>
      </div>

      {label === "Banks" && !isMonthToDate && selectedTools && <div className="date-select-section">
        <select className="select-year-month select" value={monthsByName[defaultMonth]} onChange={e => this.handleDateChange(e)}>
          {dates.map((date) => <option key={date.month} value={date.month}>{monthsByIndex[date.month]}, {date.year} {date.onFile ? " - On File" : ""}</option>)}
        </select>
        {/* <select className="select-month select" value={defaultMonth} onChange={e => this.handleMonthChange(e)}>
          {months.map(month => <option key={month} value={month}>{month.slice(0, 3)}</option>)}
        </select>
        <select className="select-year select" value={defaultYear} onChange={e => this.handleYearChange(e)}>
          {years.map(year => <option key={year} value={year}>{year}</option>)}
        </select> */}
      </div>}

      {label === "BanksSub" && <div className="date-select-section">
        <select className="select-bank-account select" onChange={e => this.handleBankChange(e)}>
          {Banks.filter(x => !!x.accountNumber).map((bank) => <option key={bank.id} value={bank.id}>{bank.name} - {bank.accountNumber}</option>)}
        </select>
      </div>}

      {selectedTools && <Fragment>
        <button className="save-btn btn" onClick={() => this.moveFile()}>Save</button>
        <button className="cancel-btn btn" onClick={() => this.closeSelectedTools()}>Cancel</button>
      </Fragment>}

      {DocPreviewModel.showSelectMenu && <div className="select-menu">
              {<Fragment>
                {Banks.map((item, index) => <div key={item.id} className="row" onClick={(obj) => this.handeSelectSection({
                    label: "Banks",
                    class: "banks-label",
                    item: item,
                    parent: "Banks"
                  })}>
                  <span className="label banks-label">Banks</span>
                  <div>{item.name} {!!item.accountNumber && (item.name !== "Unfiled Bank Files") ? `| ${item.accountNumber}` : ``}</div>
                </div>)}
                {BanksInitialSubSections.map((item, index) => <div key={item.id} className="row" onClick={(obj) => this.handeSelectSection({
                  label: "BanksSub",
                  class: "banks-label",
                  item: item,
                  parent: item.dest,
                  parentSection: 'Banks'
                })}>
                  <span className="label banks-label">Banks</span>
                  <div>{item.title}</div>
                </div>)}
                <div className="row add-bank">
                  <button className="add-folder" onClick={e => PopUpModel.open('addBankAccountForm')}>
                  <img src={addIcon} className="icon" alt="add" />Add Bank Account</button>
                  </div>
                </Fragment>}

              {SubDocs.map(item => <div key={item.id} className="row" onClick={(obj) => this.handeSelectSection({
                label: "Sub. Docs",
                class: "subdocs-label",
                item: item,
                parent: "SubDocs"
              })}>
                <span className="label subdocs-label">Sub. Docs</span>
                <div>{item.title}</div>
              </div>)}
              {Contracts.map(item => <div key={item.id} className="row" onClick={(obj) => this.handeSelectSection({
                label: "Contracts",
                class: "contracts-label",
                item: item,
                parent: "Contracts"
              })}>
                <span className="label contracts-label">Contracts</span>
                <div>{item.title}</div>
              </div>)}
              {Stips.filter(x => !x.deprecated).map(item => <div key={item.id} className="row" onClick={(obj) => this.handeSelectSection({
                label: "Stips",
                class: "stips-label",
                item: item,
                parent: "Stips"
              })}>
                <span className="label stips-label">Stips</span>
                <div>{item.title}</div>
              </div>)}
          </div>}

        </Fragment>);
  }
}
export default MovingSelect
