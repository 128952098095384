import React, { Component } from 'react';
import { observer } from 'mobx-react';

import crossIcon from '../../Images/cross-white.png';
import banksIcon from '../../Images/bank-icon.png';

import FileManagerModel from '../../Models/FileManagerModel';
import PopUpModel from '../../Models/PopUpModel';
import { NotificationManager } from '../popups/react-notifications/index';

import CloseBtn from '../buttons/CloseBtn';

@observer class EditBankAccountForm extends Component {
    state = {
        accountName: PopUpModel.updatebleFileData.name,
        number: PopUpModel.updatebleFileData.accountNumber,
        accountId: PopUpModel.updatebleFileData.id
    }

    handleNameChange(e){
      this.setState({accountName: e.target.value});
    }

    handleNumberChange(e){
      if(typeof +e.target.value && (+e.target.value === +e.target.value)){
        this.setState({number: e.target.value + ''})
      }else{
        NotificationManager.warning('Must be a number');
      }
    }

    render() {
        return (
            <div className="add-bank-account">
                <div onClick={e => {PopUpModel.closeAll()}} className="add-bank-account__close"></div>
                <div className="add-bank-account__inner">
                    <div className="head-section">
                        <p className="title"><img src={banksIcon} alt=""/> Edit Bank Account</p>
                        <CloseBtn darkBackground={true} onClick={ e=> {PopUpModel.closeAll()}} />
                    </div>
                    <label htmlFor="bankName">Bank Name</label>
                    <input value={this.state.accountName} type="text" id="bankName" onChange={(e) => this.handleNameChange(e)}/>
                    <label htmlFor="account_number">Account Number</label>
                    <input value={this.state.number} type="text" id="account_number" onChange={(e) => this.handleNumberChange(e)}/>

                    <button onClick={e => { FileManagerModel.submitUpdateBankAccountForm(this.state.accountName, this.state.number, this.state.accountId) }}
                            className="submit">Save</button>
                    <span onClick={e => { PopUpModel.closeAll() }} className="cancel-btn">Cancel</span>
                </div>
            </div>
        )
    }
}

export default EditBankAccountForm;
