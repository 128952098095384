import React, { Component } from 'react';
import grayUploadIcon from '../../Images/gray-upload-icon.png';


export default class UploadBtn extends Component {
    handleImageClick(e) {
        let input = e.target.parentNode.querySelector('.input');
        input.click();
    }

    render() {
        const { onChange } = this.props;
        return (
            <div className="btn upload-btn">
                <input multiple type="file" className="input" onChange={onChange}/>
                <img onClick={e => this.handleImageClick(e)} src={grayUploadIcon} alt=""/>
            </div>
        )
    }
}
