import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import FileBinSection from '../fileManager/fileBin/FileBinSection';

class FileManagerSection extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    this.resizeObserver = new ResizeObserver(entries => {
      this.sendMessage();
    });

    this.resizeObserver.observe(this.myRef.current);
  }

  componentWillUnmount() {
    this.resizeObserver.unobserve(this.myRef.current);
  }

  generateMessage = () => {
    const scrollHeight = Math.max(
      this.myRef.current.scrollHeight,
      this.myRef.current.offsetHeight,
      this.myRef.current.clientHeight,
    );

    return {
      iframeUrl: window.location.href,
      iframeHeight: scrollHeight,
    };
  };

  throttleSimplified = (func, ms) => {
    let isThrottled = false;
    let shouldRecall = false;

    function wrapper() {
      if (isThrottled) {
        shouldRecall = true;
        return;
      }

      func();

      isThrottled = true;

      setTimeout(function() {
        isThrottled = false;
        if (shouldRecall) {
          wrapper();
          shouldRecall = false;
        }
      }, ms);
    }

    return wrapper;
  };

  sendMessage = this.throttleSimplified(() => {
    if (!window.parent) return;
    const message = this.generateMessage();
    window.parent.postMessage(message, '*');
  }, 500);

  render() {
    return (
      <div style={{ backgroundColor: 'transparent' }} ref={this.myRef}>
        <Droppable droppableId={'FileBinFiles'}>{provided => <FileBinSection provided={provided} />}</Droppable>
      </div>
    );
  }
}

export default FileManagerSection;
