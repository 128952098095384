//https://github.com/minhtranite/react-notifications
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import PopUpModel from '../../../Models/PopUpModel';
import * as mobx from 'mobx';
import NotificationManager from './NotificationManager';

class Notification extends React.Component {
  state = {}

  static propTypes = {
    type: PropTypes.oneOf(['info', 'success', 'warning', 'error']),
    title: PropTypes.node,
    message: PropTypes.node.isRequired,
    timeOut: PropTypes.number,
    onClick: PropTypes.func,
    onRequestHide: PropTypes.func
  };

  static defaultProps = {
    type: 'info',
    title: null,
    isPopup: false,
    message: null,
    timeOut: 5000,
    onClick: () => {
    },
    onRequestHide: () => {
    }
  };

  disposer = null;

  componentDidMount = () => {
    const { timeOut, isPopup } = this.props,
          { activePopUp } = this.state;

    this.disposer = mobx.autorun(() => {
      const {activePopUp} = PopUpModel;

      if (timeOut !== 0 && activePopUp !== 'notification') {
        this.timer = setTimeout(this.requestHide, timeOut);
      }
      this.setState({activePopUp})
    })

  };

  componentWillUnmount = () => {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.disposer();
  };

  handleClick = () => {
    const { onClick, isPopup } = this.props,
          { activePopUp } = this.state;
    if (onClick) {
      onClick();
    }
    if(activePopUp !== 'notification'){
      this.requestHide();
    }
  };

  requestHide = () => {
    const { onRequestHide, type } = this.props,
          { wasAnimated } = this.state;
    if(!wasAnimated && onRequestHide){
      this.setState({wasAnimated: true}, onRequestHide());
    }
  };

  render() {
    const { type, message, title } = this.props,
          { wasAnimated } = this.state;
    const className = classnames(['notification', `notification-${type}`, {'notification-hide': this.state.wasAnimated}]);
    let currentTitle = title ? (<h4 className="title">{title}</h4>) : null;
    return (
      <div className={className} onClick={this.handleClick}>
        <div className="notification-message" role="alert">
          {currentTitle}
          <div className="message">{message}</div>
        </div>
      </div>
    );
  }
}

export default Notification;
