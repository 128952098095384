import { observable, action } from 'mobx';
import uuid from 'uuid';
import * as mobx from 'mobx';
import Helper from '../Helpers/helper'
import UploadModel from './loadModels/UploadModel';
import RootModel from './RootModel';
import DropEndModel from './DropEndModel';
import UpdateModel from './UpdateModel';
import { months } from '../Helpers/convertData';

class DatePickerModel {
    MONTHS = 12;
    @observable months = this.setYearForMonths();

    setYearForMonths(){
      let currDate = new Date();
      let currMonth = currDate.getMonth(),
          currYear = currDate.getFullYear();

      let monthsArr = months.map((month, index) => {
        return {
          id: uuid.v4(),
          name: month,
          year: (index <= currMonth) ? currYear : currYear - 1
        }
      });

      return monthsArr;
    }

    @action toggleDatePicker(subSectionId, doShow) {
        let newBanks = RootModel.Banks.map(bank => {
            if (subSectionId && bank.id.toString() === subSectionId.toString()) {
                if (doShow) {
                    bank.isDatePickerShown = doShow;
                } else {
                    bank.isDatePickerShown = !bank.isDatePickerShown;
                }
            } else {
                bank.isDatePickerShown = false;
            }
            return bank;
        });

        RootModel.Banks = newBanks;
    };

    @action setDraggableData(data){
        this.draggableData = data;
    }

    @action setDate(id) {
        let dateObj = this.months.find(month => month.id === id);
        let month = dateObj.name;
        let year = dateObj.year;

        if(DropEndModel.draggableData) {
            let draggableData = DropEndModel.draggableData;
            let {destination, draggableId} = draggableData;
            let file = Helper.findFile(destination, draggableId);

            file.year = year;
            file.month = month;
            RootModel.sortFilesByDate(destination.droppableId);

            UpdateModel.updateDocumentsPlace(file, 'Banks', destination.droppableId, 'FileBinFiles');
            this.toggleDatePicker(destination.droppableId);

            DropEndModel.setDraggableData(null);
        } else {
            UploadModel.uploadBankDocuments(month, year);
        }
    }

    @action getActiveMonth() {
        let activeMonth = this.months.find(month => month.id === this.activeMonthId);

        return activeMonth;
    }

    @action increaseYear(id) {
        let date = new Date();
        let newMonths = this.months.map(month => {
            if (month.id === id && month.year !== date.getFullYear()) {
                month.year = month.year + 1;
            }
            return month;
        });

        this.months = newMonths;
    }

    @action decreaseYear(id) {
        let newMonths = this.months.map(month => {
            if (month.id === id && month.year !== 1970) {
                month.year = month.year - 1;
            }
            return month;
        });

        this.months = newMonths;
    }

    @action increaseYearForAll() {
        const date = new Date();

        let newMonth = this.months.map(month => {
            if(month.year !== date.getFullYear()) {
                month.year++;
            }
            return month;
        });

        this.months = newMonth;
    }

    @action decreaseYearForAll() {
        let newMonth = this.months.map(month => {
            if(month.year !== 1970) {
                month.year--;
            }
            return month;
        });

        this.months = newMonth;
    }
}

const model = new DatePickerModel();

export default model;
