import {action, observable} from 'mobx';
import DocPreviewModel from './DocPreviewModel';
import TrashModel from './TrashModel';

class PopUpModel {
  @observable activePopUp = '';
  @observable deletableFileData = null;
  @observable updatebleFileData = null;
  @observable errorMessage = null;

  @action closeAll() {
    this.activePopUp = '';
    this.deletableFileData = null;
    this.errorMessage = null;
  }

  @action open(activePopUp, data) {
    if (activePopUp === 'deleteFileConfirmation') {
      this.deletableFileData = data;
    }

    if (activePopUp === 'editBankAccountForm') {
      this.updatebleFileData = data;
    }

    this.activePopUp = activePopUp;

    if (activePopUp === 'error') {
      this.errorMessage = data;
    }
  }

  @action confirmDeleteFile() {
    if (this.deletableFileData.dest === 'FileBinFolders' && !(this.deletableFileData.title && this.deletableFileData.type)) {
      let fileData = this.deletableFileData.fileData;

      this.closeAll();
      TrashModel.moveFolderToTrash(fileData);
      return;
    }

    let {fileData, dest, subSectionId, isFromReader, parentDest, parentSectionId} = this.deletableFileData;
    this.closeAll();
    if (!subSectionId && DocPreviewModel.activeDocData) {
      subSectionId = DocPreviewModel.subSectionId
    }
    TrashModel.moveFileToTrash(fileData, dest, subSectionId, isFromReader, parentDest, parentSectionId);
  }
}

const model = new PopUpModel();

export default model;
