import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { configure } from 'mobx';
import { configS3 } from './info';
import App from './Components/App';

configure({ enforceActions: 'observed' });

ReactDOM.render(<App/>, document.getElementById('root'));

serviceWorker.unregister();

if(window.AWS){
  window.AWS.config.update({
    accessKeyId : configS3.accessKeyId,
    secretAccessKey : configS3.secretAccessKey
  });



  window.AWS.config.region = configS3.region;
}

window['__react-beautiful-dnd-disable-dev-warnings'] = true; // TURNING OFF DEVELOPMENT CONSOLE NOTIFICATIONS FOR DRAG AND DROP
