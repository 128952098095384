import { action } from 'mobx';
import { saveAs } from 'file-saver';
import { NotificationManager } from '../../Components/popups/react-notifications/index';
import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';

import Helper from '../../Helpers/helper';
import RootModel from '../RootModel';
import PopUpModel from '../PopUpModel';
import FileBinModel from '../FileBinModel';
import OtherDealsModel from '../OtherDealsModel';
import TrashModel from '../TrashModel';

import { DESTINATIONS } from '../../constants/destinations';

class DownloadModel {
  @action downloadFile(fileData){
    saveAs(fileData.url, fileData.title);
  };

  @action openInNewWindow(fileData) {
    window.open(fileData.url, '_blank');
  }
  //download section as zip
  formatFileNamesForZip(files){//changes file names with same name
    let titles = {}, newFiles = [];

    files.forEach((file, index) => {
      let title = file.title;
      titles[title] = files.filter(file => file.title === title);
    })

    for(let title in titles){
      let changedFiles = [...titles[title]];
      if(changedFiles.length > 1){
        changedFiles = changedFiles.map((file, index) => {
          let newFile = {...file}, fileTitle = newFile.title.split('.');

          newFile.title = `${fileTitle[0]} ${index + 1}.${fileTitle[1]}`;
          return newFile;
        });
      }

      newFiles = newFiles.concat(changedFiles)
    }

    return newFiles;
  }

  @action downloadSubsectionFilesInZip(dest, subSectionId) {
    PopUpModel.open('notification');
    NotificationManager.info(`Downloading ${dest}' files...`, PopUpModel.activePopUp === 'notification');

      let count = 0, files = [], subSection = null;
      const zip = new JSZip();

      if(dest === 'FileBinFiles') {
        files = RootModel[dest].filter(file => file.parent_id.toString() === FileBinModel.activeFolderId.toString());
      } else {
        if(!subSectionId){
          RootModel[dest].forEach(subSection => {
            files = files.concat(subSection.files);
          });
        }else{
          subSection = RootModel[dest].find(elem => elem.id === subSectionId);
          files = subSection.files;
        }
      }

      let newFiles = this.formatFileNamesForZip(files);

      if(dest === 'FileBinFiles') this.loadFileBin();
      else{
        newFiles.forEach((file, i) => {
          let iteration = function(){
            setTimeout(() => {
              JSZipUtils.getBinaryContent( file.url, function (err, data) {
                  if(err) {
                     throw err;
                  }
                  zip.file(file.title, data, {binary:true});
                  count++;

                  if (count === files.length) {
                      zip.generateAsync({type:"blob"})
                          .then(function(content) {
                              let name = null;

                              if(dest !== 'Banks') {
                                  name = dest;
                              } else if(dest === 'Banks'){
                                if(subSectionId && !Helper.isBankMonthToDate(dest, subSectionId)){
                                  name = `${subSection.name}-${subSection.accountNumber}`;
                                }else if(subSectionId && Helper.isBankMonthToDate(dest, subSectionId)){
                                  name = 'Banks-MonthToDate';
                                }else{
                                  name = 'Banks';
                                }
                              }

                              saveAs(content, name);
                              PopUpModel.closeAll();
                          });
                  }
              });
            }, 100)
          }

          iteration.call(this, file)
          if(newFiles.length - 1 === i){
            PopUpModel.closeAll();
          }
        });
      }

  };
  //download all sections as zip
  setFoldersPath(folders){
    let updatedFolders = [];
    folders = [...FileBinModel.FileBinFolders, ...folders];

    folders.forEach(folder => {
      if(folder && folder.name && folder.id.toString() !== TrashModel.trashId.toString() && folder.id.toString() !== OtherDealsModel.otherDeals.folderId.toString()){
        let route = '', activeFolderId = folder.id;
        do {
            let foundFolder = folders.find(folder => {
              if(folder && folder.id) return folder.id.toString() === activeFolderId.toString()
            });
            route = `/${foundFolder.name}` + route
            activeFolderId = (foundFolder.parent_type === 'Deal') ? 'FileBinFiles' : foundFolder.parent_id;
        } while (activeFolderId !== 'FileBinFiles');
        route = 'FileBinFiles' + route;
        folder.route = route;
        updatedFolders.push(folder);
      }
    })

    return updatedFolders;
  }

  @action async getAllFoldersData(folder){
    let data = {
      folders: [],
      files: []
    }

    let folderData = await FileBinModel.getSubFolders(folder.id, 'Folder', true);
    let folderDocuments = await RootModel.getFolderDocuments(true, folder.id);

    data.folders = data.folders.concat(folderData);
    data.files = (data.files).concat(folderDocuments);

    if(folderData && folderData.length){
      for(let i = 0; i < folderData.length; i++){
        let subFolder = await this.getAllFoldersData(folderData[i]);
        data.folders = data.folders.concat(subFolder.folders);
        data.files = data.files.concat(subFolder.files);
      }
    }

    return data;
  }

  @action getAllFiles(){
    let files = {};

    DESTINATIONS.forEach(dest => {
      if(dest === 'FileBinFiles'){
        files[dest] = [].concat(RootModel[dest]);
      }else{
        let dealFiles = [];

        RootModel[dest].forEach(subSection => {
          dealFiles = dealFiles.concat(subSection.files);
        })
        files[dest] = dealFiles;
      }
    })

    return files;
  }

  loadFilesInZip(data, isAllFiles){
    let folders = [], files = [], allFilesLenght = 0,
    zip = new JSZip(), filesCounter = 0, allFiles = {}, fullSections;

    data.forEach(folderData => {
      folders = folders.concat(folderData.folders);
      files = files.concat(folderData.files);
    })
    folders = this.setFoldersPath(folders);

    if(isAllFiles){
      allFiles = this.getAllFiles();
      allFiles['FileBinFiles'] = [...RootModel['FileBinFiles'], ...files];

      fullSections = DESTINATIONS.filter(section => {
        allFilesLenght += allFiles[section].length;
        return allFiles[section] && allFiles[section].length > 0
      });
    }else{
      allFiles['FileBinFiles'] = [...RootModel['FileBinFiles'], ...files];
      allFilesLenght = allFiles['FileBinFiles'].length;
      fullSections = ['FileBinFiles'];
    }

    fullSections.forEach((section, i) => {
      if(allFiles[section].length > 1){
        if(section !== 'FileBinFiles'){
          allFiles[section] = this.formatFileNamesForZip(allFiles[section]);
        }
      }

      let sectionsIteration = function (filesSection) {
        setTimeout(() => {
          filesCounter = 0;
          filesSection.forEach((file) => {
            (function(file) {
              setTimeout(function() {
                JSZipUtils.getBinaryContent(file.url, function (err, data) {
                      let folderSection = section;

                      if(section === 'FileBinFiles' && file.parent_id !== 'FileBinFiles'){
                        folderSection = folders.find(folder => {
                          return folder.id.toString() === file.parent_id.toString()
                        })
                        folderSection = folderSection.route;
                      }
                      zip.folder(folderSection).file(file.title, data, {binary:true});

                      if(filesCounter === allFilesLenght - 1){
                        zip.generateAsync({type:"blob"})
                        .then(function(content){
                          let zipName = isAllFiles ? 'All Files' : section;
                          saveAs(content, zipName);
                        });
                        PopUpModel.closeAll();
                        return null;
                      }

                      filesCounter++;
                    });

                }, 1000)
              })(file)

            });

          }, 1000)
        }
      sectionsIteration.call(this, allFiles[section])
    });
  }

  loadFileBin(isAllFiles){
    const rootFolders = [...FileBinModel.FileBinFolders].filter(folder => {
      return folder.parent_id === 'FileBinFiles' && folder.id !== TrashModel.trashId && folder.id !== OtherDealsModel.otherDeals.folderId;
    });

    Promise.all(rootFolders.map((folder, i) => {
        return this.getAllFoldersData(folder);
    }))
    .then(res => {
      this.loadFilesInZip(res, isAllFiles);
    })
  }

  @action downloadAllFilesInZip(){
    PopUpModel.open('notification');
    NotificationManager.info('Downloading Files...', true);

    this.loadFileBin(true);
  }

} //END

const model = new DownloadModel();

export default model;
