import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import * as mobx from 'mobx';

import DownloadBtn from '../buttons/DownloadBtn';
import CollapseBtn from '../buttons/CollapseBtn';

import ToolbarSubsection from '../ToolbarSubsection';
import RootModel from '../../Models/RootModel';
import NavigationModel from '../../Models/NavigationModel';
import DownloadModel from '../../Models/loadModels/DownloadModel';

import Helper from '../../Helpers/helper';
import docsIcon from '../../Images/docs-icon.png';

@observer class SubDocs extends Component {
  constructor(props){
    super(props);

    this.disposer = null;
    this.state = {
      contracts: null
    }
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const collapsed = this.props.viewAll ? NavigationModel.viewAllCollapsedSections.SubDocs : NavigationModel.collapsedSections.SubDocs;
      const subDocs = RootModel.SubDocs;
      let isEmpty;
      if(subDocs.length){
        isEmpty = Helper.isEmptySection(subDocs);
      }

      this.setState({subDocs, isEmpty, collapsed});
    });
  }

  componentWillUnmount(){
    this.disposer();
  }

    render() {
      const {subDocs, isEmpty, collapsed} = this.state;

      if(!subDocs) return null;

        return (
          <div className={`toolbar__container subdocs ${collapsed ? 'container-collapsed' : ''}`}>
              <div className="head-section">
                  <p className="title">
                      <CollapseBtn collapsed={collapsed} onClick={(e, viewAll) => NavigationModel.handleSectionCollapse('SubDocs', this.props.viewAll)} />
                      <img src={docsIcon} className="icon" alt="sub docs" />
                      Submission Docs
                      <DownloadBtn disabled={isEmpty} onClick={() => DownloadModel.downloadSubsectionFilesInZip('SubDocs')} />
                  </p>
              </div>
              {!collapsed && subDocs.map(subsection => <React.Fragment key={subsection.id}>
                  <Droppable droppableId={subsection.id}>
                      {provided => <ToolbarSubsection provided={provided} dest="SubDocs" key={subsection.id} data={subsection}/>}
                  </Droppable>
              </React.Fragment>)}
          </div>
        )
    }
}

export default SubDocs;
