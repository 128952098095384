import React from 'react';
import checkIcon from '../../Images/check-icon.png';

export default function SubmitBtn(props){
    return (
      <button className={`btn submit-btn ${props.className}`} onClick={props.onClick}>
        <img src={checkIcon} alt="save changes"/>
      </button>
    )
}
