import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';

import FileBlockCut from '../FileBlockCut';
import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import DocPreviewModel from '../../../Models/DocPreviewModel';
import DownloadModel from '../../../Models/loadModels/DownloadModel';
import FileBinModel from '../../../Models/FileBinModel';
import ViewIcon from "../../../Images/view-icon.svg";
import uploadIcon from "../../../Images/add-icon-gray.png";



@observer class FilesSectionViewFile extends Component {
    constructor(props) {
        super(props);

        this.disposer = null;
        this.uploadInputRef = React.createRef();

        this.state = {
          isInTrash: false,
          fileBinFiles: [],
          activeFolderId: 'FileBinFiles'
        }
    }

    componentDidMount() {
      this.disposer = mobx.autorun(() => {
          const activeFolderId = FileBinModel.activeFolderId;

          const fileBinFiles = [...RootModel.FileBinFiles].filter(file => {
                  if(file.parent_id){
                    return file.parent_id.toString() === activeFolderId.toString()
                  }
                }),
                activeDocData = DocPreviewModel.activeDocData,
                isInTrash = TrashModel.determineIsInTrash();

          this.setState({activeFolderId, fileBinFiles, activeDocData, isInTrash});
        });
    }

    componentWillUnmount(){
      this.disposer();
    }

    render() {
      const { fileBinFiles } = this.state;
      const file = fileBinFiles.length ? fileBinFiles[0] : {};
            
      return (
          <div onClick={() => DownloadModel.openInNewWindow(file)}>
              <img src={ViewIcon} alt="upload"/>
          </div>)
    }
}

export default FilesSectionViewFile;
