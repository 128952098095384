import React, { Component } from 'react';
import { observer } from 'mobx-react';

import DatePickerModel from '../../Models/DatePickerModel';

@observer class DatePickerMonth extends Component {
    render() {
        let { name, id, year } = this.props.data;
        
        if(name.length > 5) name = name.slice(0,3);
        year = year.toString().slice(2);

        return (
            <div className='date-picker__month' onClick={e => DatePickerModel.setDate(id)}>
                {name} {year}
            </div>
        )
    }
}

export default DatePickerMonth;
