import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';

import FileBlockCut from '../FileBlockCut';
import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import DocPreviewModel from '../../../Models/DocPreviewModel';
import FileBinModel from '../../../Models/FileBinModel';


@observer class FilesSection extends Component {
    constructor(props) {
        super(props);

        this.disposer = null;

        this.state = {
          isInTrash: false,
          fileBinFiles: [],
          activeFolderId: 'FileBinFiles'
        }
    }


    componentDidMount() {
      this.disposer = mobx.autorun(() => {
          const activeFolderId = FileBinModel.activeFolderId;

          const fileBinFiles = [...RootModel.FileBinFiles].filter(file => {
                  if(file.parent_id){
                    return file.parent_id.toString() === activeFolderId.toString()
                  }
                }),
                activeDocData = DocPreviewModel.activeDocData,
                isInTrash = TrashModel.determineIsInTrash();

          this.setState({activeFolderId, fileBinFiles, activeDocData, isInTrash});
        });
    }

    componentWillUnmount(){
      this.disposer();
    }

    render() {
      const { activeDocData, fileBinFiles, isInTrash, inOtherDeals} = this.state;
            
      return (<>
                {fileBinFiles.length > 0 &&
                    fileBinFiles.map((file) => {
                    let isPreviewed = activeDocData ? (file.id.toString() === activeDocData.id.toString()) : null;

                    return <FileBlockCut key={file.id} data={file} isPreviewed={isPreviewed} isInTrash={isInTrash} inOtherDeals={inOtherDeals} />}
                )}
              </>)
    }
}

export default FilesSection;
