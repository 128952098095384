import { observable, action } from 'mobx';

import FileBinModel from './FileBinModel';
import RootModel from './RootModel';
import Helper from '../Helpers/helper';
import fetchData from '../Services/fetchData';
import { OTHER_DEALS_FOLDER, DEAL_ID } from '../constants/api';
import * as mobx from 'mobx';

class OtherDealsModel {
  @observable inOtherDeals = false;
  @observable otherDealsDocuments = [];
  @observable formatedDealsForPreview = [];//convinient format for FullPreview
  @observable otherDeals = {
    folderName: OTHER_DEALS_FOLDER,
    folderId: null
  };

  @action determineInOtherDeals(setValue){
    this.inOtherDeals = setValue || FileBinModel.activeFolderId == this.otherDeals.folderId;
    return this.inOtherDeals;
  }

  @action setDealsId(id){
    this.otherDeals.folderId = id;
  }

  @action setDocuments(otherDealsDocuments){
    this.otherDealsDocuments = otherDealsDocuments;
    let formated = [];
    otherDealsDocuments.map(deal => {
      if(deal.documents.length){
        let files = deal.documents.map(doc => {
          let file = Helper.convertToFileObject(doc);
          file.isOtherDeal = true;
          file.submission_date = deal.submission_date;

          return file;
        })
        formated = formated.concat(files)
      }
    })

    this.formatedDealsForPreview = formated;

    RootModel.sortOtherDocuments(formated, true);
  }

  getOtherDealsDocuments(init){
    let data = {
      deal_id: DEAL_ID
    }

    fetchData('merchants/deal_documents', data)// .then(res => res.json())
    .then(res => {
      return res.json()
    })
    .then(res => {
            this.setDocuments(res);
          })


  }

}

const model = new OtherDealsModel();
export default model;
