import React from 'react';
import bombIcon from '../../Images/bomb.png';

function ErrorScreen(props){
    return (
        <div className="error-container">
          <div className="error-container__error-message-wrapper">
            <img src={bombIcon} alt="Bomb icon"/>
            <h2 className="text header">Something Went Boom!</h2>
            <h4 className="more-info text">More Info:</h4>
            <h3 className="text">{props.errorMessage.toString()}</h3>
          </div>
        </div>
    )
}

export default ErrorScreen;
