import React, { Component } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx';
import uuid from 'uuid';

import trashIcon from '../../../Images/trash.svg';
import addIcon from '../../../Images/add-icon.png';

import FileBinFolder from '../FileBinFolder';
import OtherDealsFolder from '../OtherDealsFolder';
import DownloadBtn from '../../buttons/DownloadBtn';
import UploadBtn from '../../buttons/UploadBtn';

import FoldersBreadcrumbs from './FoldersBreadcrumbs';
import FilesSection from './FilesSection';

import TrashModel from '../../../Models/TrashModel';
import RootModel from '../../../Models/RootModel';
import DownloadModel from '../../../Models/loadModels/DownloadModel';
import DocPreviewModel from '../../../Models/DocPreviewModel';
import FileBinModel from '../../../Models/FileBinModel';
import UploadModel from '../../../Models/loadModels/UploadModel';
import PopUpModel from '../../../Models/PopUpModel';
import OtherDealsModel from '../../../Models/OtherDealsModel';

@observer class FileBin extends Component {
    constructor(props) {
        super(props);

        this.disposer = null;
        this.uploadInputRef = React.createRef();

        this.state = {
          isInTrash: false,
          fileBinFolders: [],
          fileBinFiles: [],
          activeFolderId: 'FileBinFiles'
        }
    }

    onDragOver = (e) => {
        e.preventDefault()
    }

    onDrop = (e) => {
        e.preventDefault();
        const files = e.dataTransfer.files;
        UploadModel.handleFilesUpload(files, 'FileBinFiles', FileBinModel.activeFolderId);//dest must be not right
    }

    openFileDialog = (e) => {
        this.uploadInputRef.current.click();
    }

    openEdit(){
      if(this.state.activeFolderId){
        let currFolder = FileBinModel.FileBinFolders.find(folder => {
          if(folder.id && this.state.activeFolderId) return folder.id.toString() === this.state.activeFolderId.toString()
        });
        if(currFolder) FileBinModel.toggleEditFolder(currFolder);
      }
    }

    componentDidMount() {
        this.disposer = mobx.autorun(() => {
          const activeFolderId = FileBinModel.activeFolderId,
                otherDeals = OtherDealsModel.otherDeals,
                { formatedDealsForPreview } = OtherDealsModel,
                {trashId} = TrashModel;

          const inOtherDeals = OtherDealsModel.determineInOtherDeals();
          let fileBinFolders = [], fileBinFiles = [],
                activeDocData = DocPreviewModel.activeDocData,
                isInTrash = TrashModel.determineIsInTrash();

                if(FileBinModel.FileBinFolders){
                  fileBinFolders = [...FileBinModel.FileBinFolders].filter(folder => (folder.parent_id.toString() === activeFolderId.toString() && (folder.id !== 'Trash')) )
                  fileBinFiles = [...RootModel.FileBinFiles].filter(file => (file.parent_id && file.parent_id.toString() === activeFolderId.toString() ))
                }

          this.setState({activeFolderId, formatedDealsForPreview, fileBinFolders, fileBinFiles, activeDocData, isInTrash, trashId, otherDeals, inOtherDeals});
        });

        this.disposer = mobx.reaction(() => FileBinModel.changingFolderId, changingFolderId => {
          this.setState({changingFolderId})
        });
    }

    componentWillUnmount(){
      this.disposer();
    }

    togglePopUp(value){
      PopUpModel.open(value);
    }

    handleSort(){
      const { fileBinFiles, formatedDealsForPreview, activeFolderId } = this.state;
      let filesToUse = (activeFolderId.toString() === OtherDealsModel.otherDeals.folderId.toString()) ? formatedDealsForPreview : fileBinFiles;
      filesToUse = filesToUse.filter(f => !f.isOtherDeal);
      if(!filesToUse || !filesToUse.length) {
          return;
      }

      let file = filesToUse[0];

      DocPreviewModel.setDocData({data: file, subSectionId: activeFolderId});
      DocPreviewModel.setCurrentLocation('FileBinFiles');
      DocPreviewModel.togglePreview('open', file);
      DocPreviewModel.toggleFullPreview(file, true);
    }

    render() {
      const {activeFolderId, activeDocData, fileBinFolders, fileBinFiles,
             isInTrash, trashId, otherDeals, inOtherDeals, changingFolderId} = this.state,
            { provided } = this.props;

      let folderPreviousDeals = null;

      return (
          <div onDragOver={this.onDragOver}
              onDrop={this.onDrop}
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={`file-manager__container ${activeDocData ? 'preview-open' : ''}`}>

            <div className="head-section">
              <FoldersBreadcrumbs />

              <div className="options">
                  <button className="file-manager-sort-btn" onClick={() => this.handleSort()}>Sort</button>
                  <span className={`delete-toggler ${isInTrash ? 'active' : ''}`}
                        onClick={() => { TrashModel.toggleTrash() }}> <img src={trashIcon} alt="delete"/> </span>
                  <UploadBtn onChange={e => UploadModel.handleFilesUpload(e.target.files, 'FileBinFiles', activeFolderId)}/>
                  <DownloadBtn onClick={() => DownloadModel.downloadSubsectionFilesInZip('FileBinFiles')}/>
              </div>
            </div>

            <div className="wrapper">

                {!inOtherDeals && <div className={`folder-section files-section ${!isInTrash ? '' : 'collapsed'}`}>
                  {!isInTrash &&
                    <button className={`add-folder `} onClick={() => PopUpModel.open('addFolderForm')}>
                      <img src={addIcon} alt="add folder"/>  Add folder
                    </button> }

                    {fileBinFolders.map(folder => {
                      if(folder.name){
                        let isOtherDeals = folder.name.toLowerCase() === otherDeals.folderName.toLowerCase() && folder.id.toString() === otherDeals.folderId.toString();
                        if(isOtherDeals) folderPreviousDeals = folder;
                        if(!(folder.name.toLowerCase() === 'trash' && folder.parent_type === "Deal") && !isOtherDeals){
                          return <FileBinFolder key={uuid.v4()} onClick={() => FileBinModel.setActiveFolder(folder.id)} data={folder} isInTrash={isInTrash}/>
                        }
                      }
                    })}

                    <FilesSection provided={provided}/>

                    {folderPreviousDeals && <FileBinFolder key={uuid.v4()}
                                   className="other-deals" isOtherDeals={true}
                                   onClick={() => FileBinModel.setActiveFolder(otherDeals.folderId)}
                                   data={ folderPreviousDeals }/>}
                </div>}

                {inOtherDeals && <OtherDealsFolder />}

            </div>
          </div>
      )
    }
}

export default FileBin;
