import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Droppable } from 'react-beautiful-dnd';
import * as mobx from 'mobx';

import DocPreviewModel from '../../Models/DocPreviewModel';

import FileBinCut from '../fileManager/fileBin/FileBinCut';
import DocPreview from '../documentPreview/DocumentPreview';

@observer class FileManagerCut extends Component {
  constructor(props){
    super(props);

    this.disposer = null;
    this.state = {
      init: true
    }
  }

  componentDidMount(){
    this.disposer = mobx.autorun(() => {
      const { activeDocData } = DocPreviewModel;

      this.setState({activeDocData})
    })
  }


  componentWillUnmount(){
    this.disposer();
  }

  render() {
    const {activeDocData} = this.state;

    return (
      <div style={{ width: "100%" }}>
        <Droppable droppableId={'FileBinFiles'}>
          {provided => <FileBinCut provided={provided} />}
        </Droppable>
        {activeDocData && <DocPreview disableFullPreview />}
      </div>
    )
  }

}

export default FileManagerCut;
