import React, { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import * as mobx from 'mobx'
import folderIcon from '../../Images/blue-folder.png';
import otherFolderIcon  from '../../Images/gray-folder.png';

import DeleteBtn from '../buttons/DeleteBtn';
import EditBtn from '../buttons/EditBtn';
import CloseBtn from '../buttons/CloseBtn';

import PopUpModel from '../../Models/PopUpModel';
import FileBinModel from '../../Models/FileBinModel';

import EditFolderNameForm from '../forms/EditFolderNameForm'
import checkIcon from '../../Images/check-icon.png';
import Helper from '../../Helpers/helper';

@observer class FolderBlock extends Component {
    openEdit(e){
      const { data } = this.props;
      FileBinModel.toggleEditFolder(data);
    }

    getFolderData(){
      const { data } = this.props;
      FileBinModel.getSubFolders();
    }

    render() {
        const { data, isOtherDeals, isInTrash } = this.props;
        
        return (
            <div className={`folder ${this.props.className}`} onClick={() => this.getFolderData()}>
                <div onClick={this.props.onClick} className="click-panel"></div>
                {isOtherDeals ? <img className="folder-icon" src={otherFolderIcon} alt="folder" /> : <img className="folder-icon" src={folderIcon} alt="folder" />}
                <span>{isOtherDeals ? data.name : Helper.shortenName(data.name, 25)}</span>

                {!isOtherDeals && <Fragment>
                  {!isInTrash && <DeleteBtn onClick={e => PopUpModel.open('deleteFileConfirmation', {
                        fileData: data,
                        dest: 'FileBinFolders'
                    })}/> }

                {!data.isEditModeOn && <EditBtn onClick={(data) => this.openEdit(data)}  className={isInTrash ? 'trash-edit' : ''} />}
                </Fragment> }

                {data.isEditModeOn && !isOtherDeals && <React.Fragment>
                  <EditFolderNameForm folder={data} />
                </React.Fragment>}
            </div>
        )
    }
}

export default FolderBlock;
