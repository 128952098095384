import React, { Component } from 'react';
import saveIcon from '../../Images/save-icon.png';

export default class SaveBtn extends Component {
    render() {
        return (
            <button className="btn save-btn" onClick={e => this.props.onClick(e)}>
                <img src={saveIcon} alt="save changes"/>
            </button>
        )
    }
}
