import { observable, action } from 'mobx';
import { NotificationManager } from '../Components/popups/react-notifications/index';

import RootModel from './RootModel';
import UpdateModel from './UpdateModel';
import PopUpModel from './PopUpModel';
import TrashModel from './TrashModel';
import OtherDealsModel from './OtherDealsModel';

import { DEAL_ID, OTHER_DEALS_FOLDER, RESERVED_FILE_NAMES } from '../constants/api';
import fetchData from '../Services/fetchData';

class FileBinModel {// OPTIMIZE: add trash model
    @observable activeFolderId = 'FileBinFiles';
    @observable parentFolderId = null;
    @observable foldersRoute = null;
    @observable FileBinFolders = null;
    @observable folderNameChanging = false;
    @observable changingFolderId = null;

    @action toggleEditFolder(folder){
      if(folder.id !== 'FileBinFiles'){
        let currFolder = this.FileBinFolders.find(f => {
            if(f.id){
              return folder.id.toString() === f.id.toString();
            }
          });
        currFolder.isEditModeOn = !currFolder.isEditModeOn;
        this.changingFolderId = (currFolder.isEditModeOn) ? folder.id : null;
        this.folderNameChanging = currFolder.isEditModeOn;
        UpdateModel.folderNameChanging = currFolder.isEditModeOn;
      }
    }

    @action async getSubFolders(parentId, parentType, isGet){
      parentId = parentId || this.activeFolderId;
      parentType = parentType || 'Folder';

      if(OtherDealsModel.otherDeals && OtherDealsModel.otherDeals.folderId && parentId.toString() === OtherDealsModel.otherDeals.folderId.toString()){
        return RootModel.getFolderDocuments(false, parentId);
      }

      let wasSubfoldersRequest = this.FileBinFolders.find(folder => folder.parent_id.toString() === parentId.toString());
      if(wasSubfoldersRequest && parentId !== TrashModel.trashId){
        RootModel.getFolderDocuments();
        return null;
      }

      let data = {
        parent_id: parentId,
        parent_type: parentType
      }

      let subFolders = await fetchData('folders', data)
      .then(res => res.json())
      .then(action(res => {
        if(res.length === 0){//to prevent fetch the next time
          this.FileBinFolders.push({parent_id: parentId});
        }
        if(!isGet){
          this.FileBinFolders = res.concat(this.FileBinFolders);
          RootModel.getFolderDocuments()
          this.updateBreadcrumbs();
        }
        return res;
      }))
      .catch(err => console.error(err));

      return subFolders;
    }

    @action getFolders(init) {
        const data = {
            parent_id: DEAL_ID,
            parent_type: "Deal"
        }

        if(init) PopUpModel.open('loader');

        fetchData('folders', data)
        .then(res => res.json())
        .then(action(res => {

            let wasTrash = false, wereOtherDeals = false;
            let folders = res.map(folder => {
              if(folder.name.toLowerCase() === 'trash'){
                wasTrash = true;
                TrashModel.trashId = folder.id;
              }
              if(folder.name === OTHER_DEALS_FOLDER){
                wereOtherDeals = true;
                OtherDealsModel.otherDeals.folderId = folder.id;
              }

              if(folder.parent_type === 'Deal') {
                folder.parent_id = 'FileBinFiles';
              }
              return folder;
            })

            if(!wasTrash){
              this.submitAddFolderForm('Trash');
            }
            if(!wereOtherDeals){
              this.submitAddFolderForm(OTHER_DEALS_FOLDER);
            }

            this.FileBinFolders = folders;
            if(PopUpModel.activePopUp !== 'error') PopUpModel.closeAll();
            // if(init) this.getSubFolders(TrashModel.trashId, "Folder");
        }))
        .catch(err => console.error(err));
    }

    @action setActiveFolder(id) {
      this.activeFolderId = id;
      this.parentFolderId = (id === 'FileBinFiles') ? null : this.activeFolderId;
      if(id !== 'Trash') TrashModel.isInTrash = false;

      this.updateBreadcrumbs();
    }

    @action updateBreadcrumbs() {//updates directory>location
        if(this.activeFolderId === 'FileBinFiles') {
            this.foldersRoute = null;
            return;
        }

        let routes = [];
        let activeFolderId = this.activeFolderId;

        if(activeFolderId !== TrashModel.trashId) {
          do {
              let folder = this.FileBinFolders.find(folder => {
                if(folder.id) return folder.id.toString() === activeFolderId.toString()
              });
              routes.push(folder)
              activeFolderId = (folder.parent_type === 'Deal') ? 'FileBinFiles' : folder.parent_id;
          } while (activeFolderId !== 'FileBinFiles');
        } else {
          routes.push({name: "Trash", id: TrashModel.trashId})
        }

        TrashModel.isInTrash = TrashModel.determineIsInTrash();
        this.foldersRoute = routes.reverse();
    }

    @action getActiveFolderId() {
        return this.activeFolderId;
    }

    @action setPreviousActiveFolder() {//move inside/outside a folder
        if(this.activeFolderId === 'FileBinFiles') return;

        if(this.activeFolderId === TrashModel.trashId) {
            // CASE WHEN WE ARE IN TRASH FOLDER AND CLICK "GO TO LEVEL UP"
            this.activeFolderId = 'FileBinFiles';
            this.parentFolderId = null;
            TrashModel.isInTrash = false;
            this.updateBreadcrumbs();
            return;
        }

        if(this.parentFolderId === 'FileBinFiles') {
            this.activeFolderId = this.parentFolderId;
            this.parentFolderId = null;
        } else {
            let folderData = this.foldersRoute[this.foldersRoute.length - 2];
            this.activeFolderId = folderData ? folderData.id : 'FileBinFiles';
            this.parentFolderId = folderData ? folderData.parent_id : 'FileBinFiles';
        }

        this.updateBreadcrumbs();
    }

    @action async submitAddFolderForm(folderName, parentId) {
      parentId = parentId || this.activeFolderId;

      let isReserved = RESERVED_FILE_NAMES.findIndex(name => name.toLowerCase() === folderName.toLowerCase() ) + 1;
      if(isReserved && TrashModel.trashId && isReserved && OtherDealsModel.otherDeals.folderId){
        NotificationManager.warning('You can\'t create a folder with this name');//move to submit
        return null;
      }
        const newFolder = {
            name: folderName
        }

        if(parentId === 'FileBinFiles'){
          newFolder.parent_type = "Deal";
          newFolder.parent_id = DEAL_ID;
        }else{
          newFolder.parent_type = "Folder";
          newFolder.parent_id = parentId;
        }


        return await fetchData('folders/create', newFolder)
        .then(res => res.json())
        .then(action(res => {

          this.FileBinFolders.push({
            ...newFolder,
            id: res,
            parent_id: (folderName.toLowerCase() === 'trash') ? "FileBinFiles" : newFolder.parent_id
          });

          if(folderName.toLowerCase() !== 'trash' && folderName !== OTHER_DEALS_FOLDER) NotificationManager.success(`New folder - "${folderName}" has been created!`);
          else{
            if(folderName.toLowerCase() === 'trash') TrashModel.setTrashId(res);
            else OtherDealsModel.setDealsId(res);
          }
          if(PopUpModel.activePopUp !== 'error') PopUpModel.closeAll();
          //refresh folders data:
          if(newFolder.parent_type === 'Folder'){
            this.getSubFolders(newFolder.parent_id, newFolder.parent_type)
          }
          else{
            this.getFolders(false);
          }

          return res;
        }))
        .catch(err => console.error(err));
    }

}

const model = new FileBinModel();
export default model;
